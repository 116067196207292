<template>
  <v-dialog
      :value="showDialog"
      :persistent="persistent"
      :width="width"
      @click:outside="persistent === true ? '' : closeDialog()"
      @keydown.esc="closeDialog()"
      scrollable
  >
    <v-card>
      <v-toolbar
          color="primary"
          dark
      >
        <span class="headline">
           {{ title }}
        </span>
        <v-spacer/>
        <slot name="headerAction"></slot>
        <v-btn
            icon
            dark
            @click="closeDialog"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-6 px-4">
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <slot name="dialogActions"></slot>
      </v-card-actions>
      <!--      <v-card-actions>-->
      <!--        <template v-for="(button, index) in buttons">-->
      <!--          <v-btn :color="button.color" @click="handleButtonClick(button.action)">{{ button.label }}</v-btn>-->
      <!--          <v-spacer v-if="index !== buttons.length - 1"></v-spacer>-->
      <!--        </template>-->
      <!--      </v-card-actions>-->
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UniversalDialog",
  props: {
    title: {
      type: String,
      default: ""
    },
    persistent: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "500px"
    },
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
</style>
