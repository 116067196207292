<template>
  <div>
<!--    <v-autocomplete-->
<!--        :label="label"-->
<!--        v-model="selected"-->
<!--        :items="items"-->
<!--        :item-text="itemText"-->
<!--        :item-value="multiple ? null : itemValue"-->
<!--        :search-input.sync="search"-->
<!--        :loading="searchLoading"-->
<!--        autocomplete="off"-->
<!--        @select="handleSelected"-->
<!--        :multiple="multiple"-->
<!--        :chips="multiple"-->
<!--        :small-chips="multiple"-->
<!--        :rules="rules"-->
<!--        :return-object="multiple"-->
<!--        :no-data-text="searchLoading ? $t('general.loading_msg') : $t('general.no_data')"-->
<!--        outlined-->
<!--        dense-->
<!--    >-->
    <v-autocomplete
        :label="label"
        v-model="selected"
        :items="items"
        :item-text="itemText"
        :item-value="multiple ? null : itemValue"
        :loading="loading"
        autocomplete="off"
        @select="handleSelected"
        :multiple="multiple"
        :chips="multiple"
        :small-chips="multiple"
        :rules="rules"
        :return-object="multiple"
        :no-data-text="loading ? $t('general.loading_msg') : $t('general.no_data')"
        outlined
        dense
    >
      <template v-slot:selection="data" v-if="multiple">
        <v-chip
            class="ma-1"
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="remove(data.item)"
        >
          {{ data.item[itemText] }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title v-html="data.item[itemText]"/>
          </v-list-item-content>
        </template>
      </template>
<!--      <template v-slot:append-item>-->
<!--        <div v-if="bottomSelect === null && searchLoading === false" v-intersect="onIntersect" class="pa-4 teal&#45;&#45;text">-->
<!--          <div>-->
<!--            <v-progress-circular-->
<!--                indeterminate-->
<!--                size="24"-->
<!--            />-->
<!--            {{$t('general.loading_msg')}}-->
<!--          </div>-->
<!--        </div>-->
<!--      </template>-->
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      default: "",
      type: String,
    },
    value: {
      required: true,
    },
    rules: {
      type: Array
    },
    apiRead: {
      default: "/api/os_countries/index",
      type: String,
    },
    multiple: {
      default: false,
      type: Boolean
    },
    model: {
      default: "name",
      type: String
    },
    itemText: {
      default: "name",
      type: String
    },
    itemValue: {
      default: "id",
      type: String
    },
    defaultFirst: {
      default: false
    },
    optionsApi: {
      default:() => ({
        filters: {
          is_active: ""
        },
        options: {
          page: 1,
          itemsPerPage: 1000,
          sortBy: [
            'updated_at'
          ],
          sortDesc: [
            false
          ],
        },
        is_single_level: true,
        show_inactive_items: false,
        is_report: false,
        show_columns: null,
        show_only_active: true,
      }),
      type: Object
    }
  },
  data() {
    return {
      name: "VirtualAutocomplete",
      search: '',
      items: [],
      selected: this.value,
      totalRows: 0,
      bottomSelect: null,
      loading: false,
      debounceTime: null,
      options: this.optionsApi
    }
  },
  created() {
    console.log(this.options, "options")
    this.options.filters[this.model] = ""
    this.getData()
  },
  watch: {
    // search: function (val) {
    //   console.log(val && val !== this.select, val, this.selected, "sel val")
    //
    //   // ovo znaci samo za select obicni ne za multiselect
    //   // val && val !== this.selected && this.handleSearchItem(val)
    //   val && this.handleSearchItem(val)
    // },
    value: function (newValue) {
      this.selected = newValue
    },
    selected: function (val) {
      this.$emit('input', val)
    }
  },
  methods: {
    handleSelected(val) {
      this.$emit('auto-select', val)
    },
    remove (item) {
      console.log(item, "item - remove")
      const index = this.selected.findIndex(obj => obj.id === item.id)
      console.log(index, this.selected, "index")
      if (index >= 0) this.selected.splice(index, 1)
    },
    getData() {
      console.log('page', this.page)
      this.loading = true
      // const apiUrl = `https://api.punkapi.com/v2/beers?page=${this.page}&per_page=${this.perPage}`
      this.$axios.post(this.apiRead, this.options)
          .then(response => {
            this.items = response.data.result

            // ako je ovaj prop setovan na true onda ce prvi element iz niza biti selektovan
            if(this.defaultFirst) {
              if(response.data.result.length > 0) {
                this.selected = response.data.result[0][this.itemValue]
              }
            }
            // this.items = [
            //   ...this.items,
            //   ...response.data.result
            // ]
            // this.totalRows = response.data.total_rows
            // this.bottomSelect = null
          }).catch(error => {
            if(error.response && error.response.data) {
              this.$swal.fire({
                icon: "error",
                title: "Greska",
                text: error.response.data.message
              })
              return
            }
            this.$swal.fire({
              icon: "error",
              title: "Greska",
              text: "Doslo je od greske. Kontaktirajte administratora"
            })
      }).finally(() => {
        this.loading = false
      })
    },
    // handleSearchItem(val) {
    //
    //   if(this.searchLoading) return
    //
    //   clearTimeout(this.debounceTime)
    //
    //   this.debounceTime = setTimeout(async () => {
    //     console.log(this.items.filter((item) => item[this.model] == val), "filtere")
    //
    //     // pretrazimo vec pulane podatke i ako imamo skipaj poziv apija
    //     if(this.items.filter((item) => item[this.model] == val).length > 0){
    //       this.bottomSelect = true
    //       return
    //     }
    //
    //     this.searchLoading = true
    //
    //     this.options.filters[this.model] = val
    //     this.options.options.page = 1;
    //     this.bottomSelect = null
    //     try {
    //       const response = await this.$axios.post(this.apiRead, this.options)
    //       this.items = response.data.result
    //       this.totalRows = response.data.total_rows
    //
    //       if (this.options.options.page > this.totalRows / this.options.options.itemsPerPage) {
    //         this.bottomSelect = true
    //       }
    //     } catch (e) {
    //       console.log(e, "handleSearchItem")
    //     } finally {
    //       this.searchLoading = false
    //     }
    //   }, 500)
    // },
    // onIntersect() {
    //   console.log('load more...')
    //
    //   if (this.options.options.page > this.totalRows / this.options.options.itemsPerPage) {
    //     this.bottomSelect = true
    //     return
    //   }
    //
    //   this.bottomSelect = null
    //   this.options.options.page += 1
    //   this.getData()
    // },
  }
}
</script>

<style scoped>

</style>
