//array, filterArray, filters
/* eslint-disable */
import axios from 'axios'
import FileDownload from "js-file-download";

function getValidationRules() {
  const requiredRule = [
    v => !!v || this.$t('validations.field')
  ];
  const integerGtZero = [
    v => !v || v.trim() === '' || (/^[1-9]\d*$/.test(v) && parseInt(v) > 0) || this.$t('validations.integer_gt_zero')
  ];
  return {
    required: [
      ...requiredRule
    ],
    required_length: [
      ...requiredRule,
      v => v === '' || v.length > 1 || this.$t('validations.length')
    ],
    required_three_char_length: [
      ...requiredRule,
      v => (v && v.length === 3) || this.$t('validations.three_chars')
    ],
    required_url_pattern: [
      ...requiredRule,
      value => {
        const pattern = /^(http(s)?:\/\/)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
        return pattern.test(value) || this.$t('validations.url_pattern_validation')
      }
    ],
    required_number_gt_zero: [
      ...requiredRule,
      value => {
        const pattern = /^(0*[1-9]\d*(\.\d+)?|0*\.\d*[1-9]\d*|[1-9]\d*)$/
        return pattern.test(value) || this.$t('validations.number_gt_zero')
      }
    ],
    integer_gt_zero: integerGtZero,
    required_integer_gt_zero: [
      ...requiredRule,
      ...integerGtZero
    ],
    required_file: [
      v => !!v || this.$t('validations.file')
    ],
    required_array: [
      ...requiredRule,
      v => !v || v.length > 0 || this.$t('validations.array'),
    ],
    required_roles: [
      ...requiredRule,
      v => v === '' || v.length > 0 || this.$t('validations.roles')
    ],
    required_companies: [
      ...requiredRule,
      v => v === '' || v.length > 0 || this.$t('validations.companies')
    ],
    required_password_length: [
      ...requiredRule,
      v => (v && v.length >= 4) || this.$t('short_password')
    ],
    password_match: [
      ...requiredRule,
      v => v === this.form.password || this.$t('validations.password_match')
    ],
  }
}

function generateHeaders(headersArray) {
  return headersArray.map(item => {
    const text = item.text || this.$t(this.translation + '.' + item.key);
    const name = item.name || item.key;
    const align = item.align || 'left';
    const sortable = item.sortable || true;
    const value = item.value || item.key;

    let header = {
      text: text,
      name: name,
      align: align,
      sortable: sortable,
      value: value
    }
    if (item.width) {
      header = {
        ...header,
        width: item.width
      }
    }
    return header;
  })
}

const formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});

function formatCurrency(value) {
  if (!value) {
    return value;
  }
  return formatter.format(value);
}

function formatDate(value) {
  if (!value) {
    return value;
  }
  const dateArray = value.split('-');
  return dateArray[2] + "." + dateArray[1] + "." + dateArray[0] + "."
}

function formatDateTime(value) {
  if (!value) {
    return value;
  }
  const [datePart, timePart] = value.split(' ');

  // Split the date part into year, month, and day
  const [year, month, day] = datePart.split('-');

// Rearrange the date parts and add separators
  const formattedDate = `${day}.${month}.${year}.`;

// Combine the formatted date with the original time part
  return `${formattedDate} ${timePart}`;
}


/*---------------------------------- univerzalni search  ----------------------------------------*/
function search() {
  console.log("filters", this.filters);
  if (this.filteredTableData.length == 0) {
    this.filteredTableData = this.tableData
  }
  this.tableData = this.filteredTableData
  Object.keys(this.filters).forEach(val => {
    if (this.filters[val] == "") {
      return;
    }
    this.tableData = this.tableData.filter(item => {
      if (item != undefined && item != null) {
        //kreiraj varijablu koja sadrzi item
        let value = item;
        //ukoliko val koji predstavlja kljuc iz fitlera sadrzi tacku,
        //to znaci da treba uci u objekat, pa se to mora raditi preko []
        if (val.indexOf(".") != -1) {
          //dobijamo svaki kljuc
          let keys = val.split(".")
          //prolazimo kroz svaki kljuc i ulazimo u value kroz loop i value dobija vrijednost od kljuca
          keys.forEach(part => {
            if (value != null) {
              value = value[part];
            }
          })
        } else {
          //ako nema tacke onda samo postaje kao sto pise
          value = item[val];
        }
        return (value + "").toLowerCase().indexOf((this.filters[val] + "").toLowerCase()) > -1
      }
    })
  });
  if (this.tableData.length == 0) {
    this.errorMessage = this.$t('no_data');
  }
}

/*---------------------------------- refresh za filtere  ----------------------------------------*/
function refresh(isReset) {
  if (this.filteredTableData.length == 0) {
    this.filteredTableData = this.tableData
  }
  Object.keys(this.filters).forEach(f => {
    this.filters[f] = ''
  });
  if (isReset) {
    this.tableData = this.filteredTableData
  } else {
    this.readTableData();
  }
}

/*---------------------------------- ne koristi se ali neka ----------------------------------------*/
function refreshTableData(api) {
  this.tableDataLoading = true;
  this.tableData = [];
  this.filteredTableData = [];
  let id = this.dataForModal.partner_data.id
  axios.get('api/' + api + '/' + id).then(response => {
    this.tableDataLoading = false;
    this.tableData = response.data;
    this.filteredTableData = response.data;
    if (this.filters != undefined) {
      this.search()
    }
  }).catch(error => {
    this.tableDataLoading = false;
    if (error.response.status == 401) {
      this.loading = false;
      this.$swal.fire({
        type: 'info',
        title: "Info",
        text: this.$t('validation.session'),
      })
      this.$router.push('/login')

    } else {
      this.$swal.fire({
        type: 'error',
        title: this.$t('validations.error'),
        text: this.$t('validations.error'),
      })
    }
  })
}

/*---------------------------------- univerzalni read, get od api  ----------------------------------------*/
function readTableData(api) {
  this.tableDataLoading = true
  this.tableData = []
  axios.get(api).then(response => {
    this.tableDataLoading = false
    this.tableData = response.data;
    this.filteredTableData = response.data;
  }).catch(error => {
    this.tableDataLoading = false
    if (error.response.status == 401) {
      this.loading = false
      this.$swal.fire({
        type: 'info',
        title: "Info",
        text: this.$t('validation.session'),
      })
      this.$router.push('/login')
    } else {
      this.$swal.fire({
        type: 'error',
        title: this.$t('validations.error'),
        text: this.$t('validations.error'),
      })
    }
  });
  Object.keys(this.filters).forEach(f => {
    this.filters[f] = ''
  });
}

/*---------------------------------- univerzalni read po datumu, get od api  ----------------------------------------*/
function readTableDataByDate(api, dateFrom, dateTo) {
  this.tableDataLoading = true
  this.tableData = []
  let data = {
    dateFrom: dateFrom,
    dateTo: dateTo
  }
  axios.post('api/' + api, data).then(response => {
    this.tableDataLoading = false
    this.tableData = response.data;
    this.filteredTableData = response.data;
    if (this.filters != undefined) {
      this.search()
    }
  }).catch(error => {
    this.tableDataLoading = false
    if (error.response.status == 401) {
      this.loading = false
      this.$swal.fire({
        type: 'info',
        title: "Info",
        text: this.$t('validation.session'),
      })
      this.$router.push('/login')
    } else {
      this.$swal.fire({
        type: 'error',
        title: this.$t('validations.error'),
        text: this.$t('validations.error'),
      })
    }
  })
}

/*---------------------------------- univerzalni read, get od api sa dodatnim parametrima /ID ----------------------------------------*/
function readTableDataById(api, id) {
  this.tableDataLoading = true
  this.tableData = []
  axios.get('api/' + api + '/' + id).then(response => {
    this.tableDataLoading = false
    this.tableData = response.data;
    this.filteredTableData = response.data;
    if (this.filters != undefined) {
      this.search()
    }
  }).catch(error => {
    this.tableDataLoading = false
    if (error.response.status == 401) {
      this.loading = false
      this.$swal.fire({
        type: 'info',
        title: "Info",
        text: this.$t('validation.session'),
      })
      this.$router.push('/login')
    } else {
      this.$swal.fire({
        type: 'error',
        title: this.$t('validations.error'),
        text: this.$t('validations.error'),
      })
    }
  })
}

/*---------------------------------- univerzalni read (POST METHOD) ----------------------------------------*/
function readTableDataPost(apiRead, showOnlyActiveRows = false, showColumns = null, additionalParameters = null) {
  this.tableDataLoading = true
  this.tableData = []
  let data = {
    show_only_active: showOnlyActiveRows,
    show_columns: showColumns
  };
  //dodaj dodatne parametre, ukoliko postoje
  data = {
    ...data,
    ...additionalParameters
  };
  axios.post(apiRead, data).then(response => {
    this.tableDataLoading = false
    this.tableData = response.data;
    this.filteredTableData = response.data;
  }).catch(error => {
    this.tableDataLoading = false
    if (error.response.status === 401) {
      this.loading = false
      this.$swal.fire({
        type: 'info',
        title: "Info",
        text: this.$t('validation.session'),
      })
      this.$router.push('/login')
    } else {
      this.$swal.fire({
        type: 'error',
        title: this.$t('validations.error'),
        text: this.$t('validations.error'),
      })
    }
  });
  Object.keys(this.filters).forEach(f => {
    this.filters[f] = ''
  });
}

/*---------------------------------- univerzalni read (POST METHOD) ----------------------------------------*/
function readTableDataPostPagination(apiRead, showOnlyActiveRows = false, showColumns = null, additionalParameters = null, isReport = false, reportName = 'report', reportType = 'pdf', reportIgnorePagination = false) {
  if (isReport) {
    this.reportDownloading = true;
  } else {
    this.tableDataLoading = true
    this.tableData = []
  }
  let data = {
    show_only_active: showOnlyActiveRows,
    show_columns: showColumns
  };
  let config = {};
  if (isReport) {
    config = {
      responseType: "blob"
    };
  }
  //dodaj dodatne parametre, ukoliko postoje
  data = {
    ...data,
    ...additionalParameters,
    is_report: isReport
  };

  // return;
  axios.post(apiRead, data, config).then(response => {
    if (isReport) {
      const FileDownload = require('js-file-download');
      this.reportDownloading = false;
      FileDownload(response.data, reportName + '.' + reportType);
    } else {
      this.tableDataLoading = false
      this.tableData = response.data.result;
      // this.filteredTableData = response.data.result;
      this.totalRows = response.data.total_rows;
      this.isRefreshingData = false;
    }
  }).catch(error => {
    if (isReport) {
      this.reportDownloading = false
    } else {
      this.tableDataLoading = false
    }
    this.loading = false
    if (!error || !error.response || !error.response.status || !error.response.data) {
      this.$swal.fire({
        type: 'error',
        title: this.$t('validations.error'),
        text: this.$t('validations.error'),
      })
      return;
    }
    if (error.response.status === 401) {
      this.$swal.fire({
        type: 'info',
        title: "Info",
        text: this.$t('validation.session'),
      })
      this.$router.push('/login')
      return;
    }
    const reader = new FileReader();
    const validationError = this.$t('validations.error');
    const sweetAlert = this.$swal;
    reader.onload = function () {
      // Convert blob to text
      const errorMessage = reader.result;
      sweetAlert.fire({
        type: 'error',
        title: error.response.status,
        text: errorMessage ? errorMessage : validationError
      })
    };
    reader.readAsText(error.response.data);
  });
}

  /*---------------------------------- univerzalni delete, delete od api + item  ----------------------------------------*/
  function onDelete(api, item, boolReturn = true, ref = null) {
    this.deleteLoadingId = item.id

    let message = this.$t('deactivate')
    if (!item.is_active) {
      message = this.$t('reactivate')
    }

    if (!boolReturn) {
      message = this.$t('delete')
    }

    this.$swal
      .fire({
        title: message,
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#ff5252",
        reverseButtons: false,
        cancelButtonText: this.$t('deny'),
        confirmButtonText: this.$t('confirm')
      })
      .then(result => {
        if (result.value) {
          axios.delete(api + '/' + item.id)
            .then(({data}) => {
              if (!boolReturn) {
                this.tableData.splice(findById(item.id, this.tableData), 1)
              }
              item.is_active = data.is_active;
              this.$store.commit('snackbarSet', {
                show: true,
                text: this.$t('operation_success'),
                color: 'success'
              })
              this.deleteLoadingId = -1;
            })
            .catch(error => {
              this.deleteLoadingId = -1
              if (error.response.status === 401) {
                this.$swal.fire({
                  type: 'info',
                  title: "Info",
                  text: this.$t('validation.session'),
                })
                this.$router.push('/login')
              } else {
                this.$swal.fire({
                  type: 'error',
                  title: this.$t('validations.error'),
                  text: error.response.data.message,
                })
              }
            });
        } else {
          this.deleteLoadingId = -1;
        }
      });
  }

  /*---------------------------------- isEmptyObjectFunction  ----------------------------------------*/
  function isEmptyObject(object) {
    return object == null
      ||
      (object // 👈 null and undefined check
        && Object.keys(object).length === 0
        && Object.getPrototypeOf(object) === Object.prototype)
  }

  /*---------------------------------- univerzalni delete, delete od api + item  ----------------------------------------*/
  function deleteCampaignItem(api, item, campaign_id, category = false) {
    this.deleteLoadingId = item.id
    let message = '';
    if (category) {
      message = this.$t('deleteWithWarning')
    } else {
      message = this.$t('delete')
    }
    this.$swal.fire({
      title: message,
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#4caf50",
      cancelButtonColor: "#ff5252",
      reverseButtons: false,
      cancelButtonText: this.$t('deny'),
      confirmButtonText: this.$t('confirm')
    })
      .then(result => {
        if (result.value) {
          axios.delete('api/' + api + '/' + campaign_id + '/' + item.id).then(response => {
            this.tableData.splice(findById(item.id, this.tableData), 1)
            this.deleteLoadingId = -1;
            this.$emit('editEmit', response.data);
          }).catch(error => {
            this.deleteLoadingId = -1
            if (error.response.status === 401) {
              this.deleteLoadingId = -1;
              this.loading = false
              this.$swal.fire({
                type: 'info',
                title: "Info",
                text: this.$t('validation.session'),
              })
              this.$router.push('/login')
            } else {
              this.$swal.fire({
                type: 'error',
                title: this.$t('validations.error'),
                text: this.$t('validations.error'),
              })
            }
          });
        } else {
          this.deleteLoadingId = -1;
        }
      });
  }

  function deleteCampaignPartner(api, item, category = false) {
    this.deleteLoadingId = item.pivot.id
    let message = '';
    if (category) {
      message = this.$t('deleteWithWarning')
    } else {
      message = this.$t('delete')
    }
    this.$swal.fire({
      title: message,
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#4caf50",
      cancelButtonColor: "#ff5252",
      reverseButtons: false,
      cancelButtonText: this.$t('deny'),
      confirmButtonText: this.$t('confirm')
    })
      .then(result => {
        if (result.value) {
          axios.delete('api/' + api + '/' + item.pivot.id).then(response => {
            this.tableData.splice(findById(item.id, this.tableData), 1)
            this.deleteLoadingId = -1;
            this.$emit('editEmit', response.data);
          }).catch(error => {
            this.deleteLoadingId = -1
            if (error.response.status === 401) {
              this.deleteLoadingId = -1;
              this.loading = false
              this.$swal.fire({
                type: 'info',
                title: "Info",
                text: this.$t('validation.session'),
              })
              this.$router.push('/login')
            } else {
              this.$swal.fire({
                type: 'error',
                title: this.$t('validations.error'),
                text: this.$t('validations.error'),
              })
            }
          });
        } else {
          this.deleteLoadingId = -1;
        }
      });
  }


  /*---------------------------------- univerzalni delete, delete od api + item  ----------------------------------------*/
  function onShow(api, item, message) {
    this.showLoadingId = item.id
    this.$swal
      .fire({
        title: message,
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#ff5252",
        reverseButtons: false,
        cancelButtonText: this.$t('deny'),
        confirmButtonText: this.$t('confirm')
      })
      .then(result => {
        if (result.value) {
          axios.put('api/' + api + '/activate/' + item.id).then(({data}) => {
            item.is_visible = data.is_visible ? true : false;
            if (data.updated_at) {
              item.updated_at = data.updated_at;
            }
            this.$store.commit('snackbarSet', {
              show: true,
              text: this.$t('activate.success'),
              color: 'success'
            })
            this.showLoadingId = -1;
          }).catch(error => {
            let message = error.response.data.message;
            if (error.response.status == 401) {
              this.loading = false
              this.$swal.fire({
                type: 'info',
                title: "Info",
                text: this.$t('validation.session'),
              })
              this.$router.push('/login')
            } else if (error.response.status == 422) {
              this.$swal.fire({
                type: 'error',
                title: this.$t('validations.error'),
                text: this.$t('validation.' + message),
              })
            } else {
              this.$swal.fire({
                type: 'error',
                title: this.$t('validations.error'),
                text: this.$t('validations.error'),
              })
            }
            this.showLoadingId = -1;
          });
        } else {
          this.showLoadingId = -1;
        }
      })
  }

  /*---------------------------------- univerzalni delete, delete od api + item  ----------------------------------------*/
  function onDeleteRefresh(api, item) {
    this.deleteLoadingId = item.id
    let message = this.$t('deactivate')
    if (!item.is_active) {
      message = this.$t('reactivate')
    }
    this.$swal
      .fire({
        title: message,
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#ff5252",
        reverseButtons: false,
        cancelButtonText: this.$t('deny'),
        confirmButtonText: this.$t('confirm')
      })
      .then(result => {
        if (result.value) {
          axios.delete('api/' + api + '/' + item.id).then(({data}) => {
            item.is_active = data.is_active ? 1 : 0;
            item = data;
            this.readTableDataById(this.api, this.dataForModal.id);
            this.refresh(true);
            this.$store.commit('snackbarSet', {
              show: true,
              text: this.$t('edit_success'),
              color: 'success'
            })
            this.deleteLoadingId = -1;
          });
        } else {
          this.deleteLoadingId = -1;
        }
      }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    });
  }

  /*---------------------------------- univerzalno dodavanje post od api  ----------------------------------------*/
  function add(api) {
    if (!this.$refs.form.validate()) {
      this.$store.commit('snackbarSet', {
        show: true,
        text: this.$t('snackbar_required'),
        color: 'error'
      })
      return;
    }
    this.loading = true;
    axios.post(api, this.form).then((response) => {
      this.$emit('addEmit', response.data)
      this.closeDialog();
      this.loading = false;
    })
      .catch(error => {
        if (error.response.status === 401) {
          this.loading = false
          this.$swal.fire({
            type: 'info',
            title: "Info",
            text: this.$t('validation.session'),
          })
          this.$router.push('/login')
        } else {
          this.loading = false
          this.$swal.fire({
            type: 'error',
            title: this.$t('validations.error'),
            text: error.response.data.message,
          })
        }
      })
  }

  /*---------------------------------- univerzalni emit za dodavanje  ----------------------------------------*/
  function addEmit(item, ref) {
    // console.log('totalRows', this.totalRows);
    const totalRows = ref ? ref.$data.totalRows : this.totalRows;
    if (totalRows || totalRows === 0) {
      // console.log('length', this.tableData.length);
      //ako se radi paginacija onda postoji varijabla totalRows pa je povecavamo i ubacujemo dodati element na prvo mjesto u nizu
      if (ref) {
        ref.$data.totalRows++;
        ref.$data.tableData.unshift(item)
      } else {
        this.totalRows++;
        this.tableData.unshift(item)
      }
    } else {
      //ako se ne radi paginacija onda samo dodaj zapis u tabelu
      if (ref) {
        ref.$data.tableData.push(item)
      } else {
        this.tableData.push(item)
      }

    }
    this.$store.commit('snackbarSet', {
      show: true,
      text: this.$t('add_success'),
      color: 'success'
    })
  }

  /*---------------------------------- univerzalni edit za put od api  ----------------------------------------*/
  function edit(api, item) {
    if (!this.$refs.form.validate()) {
      this.$store.commit('snackbarSet', {
        show: true,
        text: this.$t('snackbar_required'),
        color: 'error'
      })
      return;
    }
    this.loading = true;
    axios.put(api + '/' + item.id, this.form).then((response) => {
      this.$emit('editEmit', response.data)
      this.$store.commit('snackbarSet', {
        show: true,
        text: this.$t('edit_success'),
        color: 'success'
      })
      this.closeDialog();
      this.loading = false;
    }).catch(error => {
      if (error.response.status == 422) {
        this.loading = false;
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: error.response.data.message,
        })
      } else if (error.response.status == 434) {
        this.loading = false;
        this.loading = false
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: "Vec postoji (Status: Neaktivan)",
        })
      } else if (error.response.status == 436) {
        this.loading = false;
        this.loading = false
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: "Neispravni datumi",
        })
      } else if (error.response.status == 435) {
        this.loading = false;
        this.loading = false
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: "Izmjene zabranjene, koristi se u evidencijama",
        })
      } else if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.loading = false;
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  /*---------------------------------- univerzalni emit za edit  ----------------------------------------*/
  function editEmit(item, ref) {
    if (ref) {
      ref.$data.tableData.splice(findById(item.id, ref.$data.tableData), 1, item)
    } else {
      this.tableData.splice(findById(item.id, this.tableData), 1, item)
    }
  }

  /*---------------------------------- TAX OFFICE - search_registry_by_pib  ----------------------------------------*/
  function taxOfficeSearchRegistryByPib(pin) {
    if (!pin) {
      this.$swal.fire({
        type: 'error',
        title: this.$t('tax_office.no_pin')
      })
      return;
    }
    this.loadingTaxOfficeSearchByPin = true;
    axios.post('/api/tax_office/search_registry_by_pib', {
      pin: pin
    }).then((response) => {
      this.taxOfficeRegistryData = response.data;
      this.loadingTaxOfficeSearchByPin = false;
    }).catch(error => {
      if (error.response.status === 401) {
        this.loadingTaxOfficeSearchByPin = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.loadingTaxOfficeSearchByPin = false
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: error.response.data.message,
        })
      }
    })
  }


  /*---------------------------------- univerzalna pomocna metoda za trazenje pozicije u nizu  ----------------------------------------*/
  function findById(id, array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        return i;
      }
    }
    return -1;
  }

  /*---------------------------------- univerzalna read countries  ----------------------------------------*/
  function readCountries() {
    axios.get('api/countries').then(response => {
      this.countries = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  /*---------------------------------- univerzalna read cities  ----------------------------------------*/
  function readCities() {
    axios.get('api/cities').then(response => {
      this.cities = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  /*---------------------------------- univerzalna read worker types  ----------------------------------------*/
  function readWorkerTypes() {
    axios.get('api/workerType').then(response => {
      this.workerTypes = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  /*---------------------------------- univerzalna read partner types  ----------------------------------------*/
  function readPartnerTypes() {
    axios.get('api/partnerType').then(response => {
      this.partnerTypes = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  /*---------------------------------- univerzalna read banks  ----------------------------------------*/
  function readBanks() {
    axios.get('api/bank').then(response => {
      this.banks = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  /*---------------------------------- univerzalna read contact types  ----------------------------------------*/
  function readContactTypes() {
    axios.get('api/contactType').then(response => {
      this.contactTypes = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  /*---------------------------------- univerzalna read items  ----------------------------------------*/
  function readItems() {
    axios.get('api/items').then(response => {
      this.items = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  /*---------------------------------- univerzalna read items  ----------------------------------------*/
  function readPatners() {
    axios.get('api/partners').then(response => {
      this.partners = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  /*---------------------------------- univerzalna read items  ----------------------------------------*/
  function readPatnersWithCash() {
    axios.get('api/partnersWithCash').then(response => {
      this.partners = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  /*---------------------------------- univerzalna read workers  ----------------------------------------*/
  function readWorkers() {
    axios.get('api/workers').then(response => {
      this.workers = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  /*---------------------------------- univerzalna read statuses  ----------------------------------------*/
  function readStatuses() {
    axios.get('api/status').then(response => {
      this.statuses = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  /*---------------------------------- univerzalna read active countries  ----------------------------------------*/
  function readCountriesActive() {
    axios.get('api/countries').then(response => {
      this.countries = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  /*---------------------------------- univerzalna read active worker types  ----------------------------------------*/
  function readWorkerTypesActive() {
    axios.get('api/workerTypeActive').then(response => {
      this.workerTypes = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  /*---------------------------------- univerzalna read active partner types  ----------------------------------------*/
  function readPartnerTypesActive() {
    axios.get('api/partnerTypeActive').then(response => {
      this.partnerTypes = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  function readCategoriesActive() {
    axios.get('api/categoriesActive').then(response => {
      this.categories = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  function readProducts() {
    axios.get('api/products').then(response => {
      this.products = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  function readPartnerUsers() {
    axios.get('api/partnerUsers').then(response => {
      this.users = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  function readPartnersActive() {
    axios.get('api/partnersActive').then(response => {
      this.partners = response.data;
      this.filterPartnersDataTable();
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  function readPartnersWithCategories() {
    this.loading = true;
    axios.post('api/partnersWithCategories', {categories: this.categories}).then(response => {
      this.loading = false;
      this.tableData = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.loading = false;
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
        this.loading = false;
      }
    })
  }

  /*---------------------------------- univerzalna read active banks  ----------------------------------------*/
  function readBanksActive() {
    axios.get('api/bankActive').then(response => {
      this.banks = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  /*---------------------------------- univerzalna read active contact types  ----------------------------------------*/
  function readContactTypesActive() {
    axios.get('api/contactTypeActive').then(response => {
      this.contactTypes = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  /*---------------------------------- univerzalna read active items  ----------------------------------------*/
  function readItemsActive() {
    axios.get('api/itemsActive').then(response => {
      this.items = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  /*---------------------------------- univerzalna read active workers  ----------------------------------------*/
  function readWorkersActive() {
    axios.get('api/workersActive').then(response => {
      this.workers = response.data;
    }).catch(error => {
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  /*---------------------------------- univerzalna read notifications  ----------------------------------------*/
  function readNotifications() {
    axios.get('api/getCommentInfo')
      .then(response => {
        let item = response.data
        if (item.evidentions == 'null') {
          item.evidentions = []
        }
        localStorage.setItem('notifications', JSON.stringify(item))
      })
      .catch(error => {
        if (error.response != undefined && error.response.status == 401) {
          this.loading = false
          this.$swal.fire({
            type: 'info',
            title: "Info",
            text: this.$t('validation.session'),
          })
          this.$router.push('/login')
        } else {
          this.$swal.fire({
            type: 'error',
            title: this.$t('validations.error'),
            text: this.$t('validations.error'),
          })
        }
      })
  }


  function downloadReport(data, name, extension) {
    this.reportDownloading = true;
    const FileDownload = require('js-file-download');
    // axios({
    //   url: 'api/getReport',
    //   method: "POST",
    //   responseType: "blob" // important
    // })
    axios.post('/api/getReport', data, {
      responseType: "blob"
    }).then(response => {
      this.reportDownloading = false;
      FileDownload(response.data, name + '.' + extension);
    }).catch(error => {
      this.reportDownloading = false;
      if (error.response.status == 401) {
        this.loading = false
        this.$swal.fire({
          type: 'info',
          title: "Info",
          text: this.$t('validation.session'),
        })
        this.$router.push('/login')
      } else {
        this.$swal.fire({
          type: 'error',
          title: this.$t('validations.error'),
          text: this.$t('validations.error'),
        })
      }
    })
  }

  export {
    getValidationRules,
    generateHeaders,
    formatCurrency,
    formatDate,
    formatDateTime,
    search,
    readPatners,
    refresh,
    refreshTableData,
    readTableData,
    readTableDataPost,
    readTableDataPostPagination,
    onDelete,
    add,
    addEmit,
    edit,
    findById,
    editEmit,
    readCountries,
    readWorkerTypes,
    readPartnerTypes,
    readPartnersWithCategories,
    readTableDataById,
    readBanks,
    readContactTypes,
    readWorkers,
    readItems,
    readStatuses,
    onDeleteRefresh,
    onShow,
    deleteCampaignItem,
    downloadReport,
    readBanksActive,
    readContactTypesActive,
    readCountriesActive,
    readItemsActive,
    readPartnerTypesActive,
    readWorkersActive,
    readWorkerTypesActive,
    readNotifications,
    readTableDataByDate,
    readCategoriesActive,
    readPartnerUsers,
    readProducts,
    readCities,
    readPartnersActive,
    deleteCampaignPartner,
    isEmptyObject,
    taxOfficeSearchRegistryByPib
  }
