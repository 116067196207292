<template>
  <div>
  <v-card class="pa-0 ma-0" elevation="0" tile>
    <div class="elevation-2 pa-2">
      <v-row>
        <v-col cols="4" lg="2"  class="text-subtitle-1 flex-row">
          <v-icon>
            mdi-format-title
          </v-icon>
          {{ $t(translation + '.title') }}:
        </v-col>
        <v-col cols="8" lg="10">
          <div class="text-body-1 font-weight-bold primary--text">
            {{ adData?.title }}
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="elevation-2 pa-2">
      <v-row>
        <v-col cols="4" lg="2" class="text-subtitle-1 flex-row">
          <v-icon>
            mdi-list-box-outline
          </v-icon>
          {{ $t(translation + '.description') }} :
        </v-col>
        <v-col cols="8" lg="10" class="text-body-1 font-weight-bold primary--text">
          {{ adData?.description === 'null' ? "" : adData?.description }}
        </v-col>
      </v-row>
    </div>
    <div class="elevation-2 pa-2">
      <v-row>
        <v-col cols="4" lg="2" class="text-subtitle-1 flex-row">
          <v-icon>
            mdi-cash
          </v-icon>
          {{ $t(translation + '.currency') }}:
        </v-col>
        <v-col cols="8" lg="10" class="text-body-1 font-weight-bold primary--text">
          {{ adData?.ad_currency?.name }}
        </v-col>
      </v-row>
    </div>

    <div class="elevation-2 pa-2">
      <v-row>
        <v-col cols="4" lg="2" class="text-subtitle-1 flex-row">
          <v-icon>
            mdi-progress-question
          </v-icon>
          {{ $t(translation + '.status') }}:
        </v-col>
        <v-col  cols="8" lg="10" class="text-body-1 font-weight-bold text-uppercase primary--text">
          {{ adData?.ad_status?.name }}
        </v-col>
      </v-row>
    </div>
    <div class="elevation-2 pa-2">
      <v-row>
        <v-col  cols="4" lg="2" class="text-subtitle-1 flex-row">
          <v-icon>
            mdi-toolbox-outline
          </v-icon>
          {{ $t(translation + '.services') }}:
        </v-col>
        <v-col cols="8" lg="10" class="text-body-1 font-weight-bold ma-0 pa-0 pt-1 primary--text">
          <v-col class="ma-2 pa-0" v-for="(service, index) in adData.services" :key="index">
            - {{ service?.name }}
          </v-col>
        </v-col>
      </v-row>
    </div>
    <div class="elevation-2 pa-2">
      <v-row>
        <v-col cols="4" lg="2" class="text-subtitle-1 flex-row px-0 pl-2">
          <v-icon>
            mdi-clock-time-eight-outline
          </v-icon>
          {{ $t(translation + '.working_days') }}:
        </v-col>
        <v-col  cols="8" lg="10" class="text-body-1 font-weight-bold ma-0 pa-0 pt-1 primary--text">
          <v-col class="ma-2 pa-0" v-for="(days, index) in adData.ad_days" :key="index">
            - {{ days.days.name }}
            <v-col class="ma-0 pa-0 ml-5" v-for="(city, index) in days.times" :key="index">
              {{ city?.start_at + '-' + city?.stop_at }}
            </v-col>
          </v-col>
        </v-col>
      </v-row>
    </div>
    <div class="elevation-2 pa-2">
      <v-row>
        <v-col cols="4" lg="2" class="text-subtitle-1 flex-row">
          <v-icon>
            mdi-map-marker
          </v-icon>
          {{ $t(translation + '.location') }}:
        </v-col>
        <v-col cols="8" lg="10" class="text-body-1 font-weight-bold ma-0 pa-0 primary--text">
          <v-col class="ma-2 pa-0" v-for="(country, index) in adData.ad_countries" :key="index">
            - {{ country?.os_countries?.name }}
            <v-col class="ma-0 pa-0 ml-5" v-for="(city, index) in country.ad_places" :key="index">
              - {{ city?.os_cities?.name }}
            </v-col>
          </v-col>
        </v-col>
      </v-row>
    </div>
    <div class="elevation-2 pa-2">
      <v-row>
        <v-col cols="4" lg="2" class="text-subtitle-1 flex-row">
          <v-icon>
            mdi-account
          </v-icon>
          {{ $t(translation + '.user') }}:
        </v-col>
        <v-col cols="8" lg="10" class="text-body-1 font-weight-bold primary--text">
          {{ adData?.users?.full_name }}
        </v-col>
      </v-row>
    </div>
    <div class="elevation-2 pa-2">
      <v-row>
        <v-col cols="4" lg="2" class="text-subtitle-1 flex-row">
          <v-icon>
            mdi-cash-100
          </v-icon>
          {{ $t(translation + '.price') }}:
        </v-col>
        <v-col cols="8" lg="10" class="text-body-1 font-weight-bold primary--text">
          {{ parseFloat(adData?.price_from).toFixed(2) }} {{ adData?.price_to ? (' - ' + parseFloat(adData?.price_to).toFixed(2)) : "" }}
        </v-col>
      </v-row>
    </div>
    <div class="elevation-2 pa-2">
      <v-row>
        <v-col cols="4" lg="2" class="text-subtitle-1 flex-row">
          <v-icon>
            mdi-clock-outline
          </v-icon>
          {{ $t(translation + '.date') }}:
        </v-col>
        <v-col cols="8" lg="10" class="text-body-1 font-weight-bold primary--text">
          <!--          {{adData?.created_at.substring(0, 10)}}-->
          {{ adData?.updated_at.substring(0, 16)}}
        </v-col>
      </v-row>
    </div>
    <div class="elevation-2 pa-2">
      <v-row>
        <v-col cols="4" lg="2" class="text-subtitle-1 flex-row">
          <v-icon>
            mdi-image-multiple-outline
          </v-icon>
          {{ $t(translation + '.pictures') }}:
        </v-col>
        <v-col cols="8" lg="10">
          <v-row class="ma-0 pa-0">
            <v-col v-if="adData.ad_photos.length > 0" v-for="(image, index) in adData.ad_photos" :key="index" class="d-flex child-flex" cols="4">
              <v-img
                  @click="openImgDialog(image.photo_url)"
                  :src="image.photo_url"
                  aspect-ratio="1"
                  class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                    />
                  </v-row>
                </template>
              </v-img>
            </v-col>
            <v-col v-else cols="4">
              {{$t(translation + '.no_picture')}}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-card>
  <vue-easy-lightbox
      :visible="showImgDialog"
      :imgs="currPic"
      :moveDisabled="false"
      :escDisabled="false"
      :index="0"
      @hide="showImgDialog = false"
  />
  </div>

</template>
<script>
export default {
  name: "InfoDialog",
  props: {
    adData: {
      default: null
    }
  },
  data() {
    return {
      translation: "infoAdd",
      showImgDialog: false,
      currPic: '',
    }
  },
  methods: {
    openImgDialog(image){
      this.currPic = image
      this.showImgDialog = true
    },
  }
}
</script>
<style scoped>

</style>