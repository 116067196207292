import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#2b2b41',
                secondary: '#be1e2d',
                // tertiary: '#495057',
                accent: '#eeeeee',
                error: '#be1e2d',
                // info: '#00d3ee',
                // success: '#3F6ECA',
                // warning: '#ffa21a'
            },
            dark: {
                primary: '#2b2b41',
                secondary: '#be1e2d',
                error: '#be1e2d',
                accent: '#2b2b41',
            }
        }
    },
    icons: {
        iconfont: "mdi",
    }
});
