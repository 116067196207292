<template>
  <div>
    <!--    <table-picker/>-->
    <data-table-pagination
        ref="dataTable"
        :title="$t(translation + '.title')"
        :headers="headers"
        :translation="translation"
        :append-item="dataTablePaginationData.addItem"
        :edit-item="dataTablePaginationData.editItem"
        :delete-item="dataTablePaginationData.deleteItem"
        :api-read="dataTablePaginationData.apiRead"
        @success-delete="dataTablePaginationData.deleteItem = null"
        @success-append="dataTablePaginationData.addItem = null"
        @success-edit="dataTablePaginationData.editItem = null"
    >
      <template v-slot:toolbarAction>
        <v-btn color="primary" @click="addItem()">
          <v-icon dark>mdi-plus</v-icon>
          {{ $t('general.add') }}
        </v-btn>

        <v-btn class="ml-2" color="warning" @click="$refs.dataTable.resetData()">
          <v-icon dark>mdi-refresh</v-icon>
        </v-btn>
      </template>
      <template v-slot:action-buttons="{item}">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="warning"
                icon
                @click="editItem(item)"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('general.edit') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="error"
                icon
                :loading="deleteLoadingId === item.id"
                @click="deleteItem(item)">
              <v-icon>{{ item.is_active ? 'mdi-delete' : 'mdi-backup-restore' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ item.is_active ? $t('general.delete') : $t('general.return') }}</span>
        </v-tooltip>
      </template>
    </data-table-pagination>

    <universal-dialog
        v-if="dialog.show"
        :title="dialog.title"
        :show-dialog="dialog.show"
        @close="closeDialog"
    >
      <universal-form
          ref="refForm"
          v-if="dialog.show"
          :fields="form.fields"
          :edit-item="form.item"
          @submit-form="handleSubmitData"
      />
      <template v-slot:dialogActions>
        <v-sheet class="text-end" width="100%">
          <v-spacer/>
          <v-btn @click="closeDialog" :disabled="loading" color="error">
            {{ $t('general.cancel') }}
          </v-btn>
          <v-btn
              class="ml-5"
              @click="getFormData"
              :loading="loading"
              color="primary">
            {{ form.item !== null ? $t('general.edit') : $t('general.add') }}
          </v-btn>
        </v-sheet>
      </template>
    </universal-dialog>
  </div>
</template>

<script>
import UniversalForm from "@/components/UniversalForm";
import DataTablePagination from "@/components/DataTablePagination";
import UniversalDialog from "@/components/UniversalDialog";
import {CRUD_VARS} from "@/utils/consts";
import {mapMutations} from "vuex";
import {
  addItem,
  closeDialog,
  deleteItem,
  editItem,
  generateHeaders,
  generateFields,
  handleSubmitData,
  showSwalPopUpConfirmation,
  getFormData
} from "@/utils/functions";
import {validationRules} from "@/utils/validationRules";

export default  {
  name: "CurrencyView",
  components: {
    'universal-form': UniversalForm,
    "data-table-pagination": DataTablePagination,
    "universal-dialog": UniversalDialog
  },
  data() {
    return {
      translation: "currencies",
      loading: false,
      deleteLoadingId: -1,

      dataTablePaginationData: {
        apiRead: '/api/currencies/index',
        addItem: null,
        editItem: null,
        deleteItem: null
      },
      form: {
        fields: [],
        item: null
      },
      ...CRUD_VARS
    }
  },
  computed: {
    headers() {
      return this.generateHeaders([
        {key: "updated_at", text: this.$t(this.translation + '.updated_at')},
        {key: "name", text: this.$t(this.translation + '.name')},
        {key: "short_name", text: this.$t(this.translation + '.short_name')},
        {key: "sign", text: this.$t(this.translation + '.sign')},
        {key: "is_active", text: this.$t(this.translation + '.active')},
        {key: "actions", text: this.$t('general.actions'), sortable: false}
      ])
    },
  },
  created() {
    this.apiStore =  "/api/currencies/store"
    this.apiUpdate= "/api/currencies/update"
    this.apiDelete= "/api/currencies/destroy"
    this.sucMessageEdit= this.$t(this.translation + '.sucMessageEdit')
    this.sucMessageStore= this.$t(this.translation + '.sucMessageStore')
    this.sucMessageDelete= this.$t(this.translation + '.sucMessageDelete')
    this.sucMessageReturn= this.$t(this.translation + '.sucMessageReturn')

    this.form.fields = this.generateFields([
      {
        name: this.$t(this.translation + '.name'),
        model: "name",
        rules: [validationRules.required]
      },
      {
        name: this.$t(this.translation + '.short_name'),
        model: "short_name",
        rules: [validationRules.required]
      },
      {
        name: this.$t(this.translation + '.sign'),
        model: "sign",
      }
    ]);
  },
  methods: {
    ...mapMutations("snackbar", {
      setSnackbar: "setSnackbar"
    }),
    closeDialog,
    addItem,
    generateFields,
    showSwalPopUpConfirmation,
    generateHeaders,
    editItem,
    deleteItem,
    handleSubmitData,
    getFormData
  }
}

</script>

<style scoped>

</style>