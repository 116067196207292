<template>
  <div>
    <!--    <v-text-field-->
    <!--        :id="id"-->
    <!--        :label="label"-->
    <!--        v-model="picker[value]"-->
    <!--        append-icon="mdi-magnify"-->
    <!--        @click="openDialog"-->
    <!--        :hide-details="hideDetails"-->
    <!--        :rules="rules"-->
    <!--        outlined-->
    <!--        dense-->
    <!--    />-->
    <v-select
        :label="label + `${rules.length > 0 ? ' *' : ''}`"
        outlined
        dense
        readonly
        v-model="selected"
        :items="selectItems"
        @click="disabled ? '' : openDialog()"
        @click:append="disabled ? '' : openDialog()"
        append-icon="mdi-magnify"
        class="ma-0 pa-0"
        small-chips
        multiple
        :disabled="disabled"
        :error-messages="validationErrors"
        :rules="rules"
    >
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip
            class="ma-1"
            color="primary"
            v-bind="attrs"
            :input-value="selected"
            close
            @click="select"
            @click:close="remove(item)"
        >
          <strong>{{ item[itemText] }}</strong>
        </v-chip>
      </template>
    </v-select>
    <universal-dialog
        :width="widthDialog"
        v-if="dialog.show"
        :title="dialog.title"
        :show-dialog="dialog.show"
        @close="closeDialog"
    >
      <template v-slot:headerAction>
        <v-btn
            color="error"
            @click="confirm"
        >
          {{ $t('general.confirm') }}
        </v-btn>
      </template>
      <data-table-pagination
          ref="dataTable"
          :headers="headers"
          :api-read="apiRead"
          :prop-filter="propFilter"
          :prop-selected="selected"
          :single-select="singleSelect"
      />
    </universal-dialog>
  </div>
</template>

<script>

import {generateHeaders, closeDialog} from "@/utils/functions";
import UniversalDialog from "./UniversalDialog";
import DataTablePagination from "./DataTablePagination";

export default {
  name: "DataTablePicker",
  props: {
    id: {
      default: "",
      type: String
    },
    apiRead: {
      default: "",
      type: String
    },
    label: {
      default: "",
      type: String
    },
    hideDetails: {
      default: false,
      type: Boolean
    },
    tableHeader: {
      default: () => [],
      type: Array
    },
    singleSelect: {
      default: true,
    },
    itemText: {
      default: "name",
      type: String,
    },
    itemValue: {
      default: "id",
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    propFilter: {
      default: () => {
      },
      type: Object
    },
    value: {},
    rules: {
      default: () => []
    },
    widthDialog: {
      default: "500px"
    }
  },
  components: {
    "universal-dialog": UniversalDialog,
    "data-table-pagination": DataTablePagination
  },
  data() {
    return {
      dialog: {
        show: false,
        title: ""
      },
      selectItems: this.value,
      selected: this.value,
      validationErrors: []
    }
  },
  computed: {
    headers() {
      return this.generateHeaders(this.tableHeader)
    },
  },
  watch: {
    value: function (newValue) {
      this.validationErrors = []
      this.selected = newValue
      this.selectItems = newValue
    },
    selected: function (val) {
      this.$emit('input', val)
    }
  },
  methods: {
    remove(item) {
      this.selectItems = this.selectItems.filter((tItem) => tItem.id !== item.id)
      this.selected = this.selectItems
      this.$emit('input', this.selected)

    },
    generateHeaders,
    closeDialog,
    confirm() {
      const items = this.$refs.dataTable.getSelectedData();
      this.selectItems = items
      this.selected = this.selectItems
      this.closeDialog()
    },
    validate() {
      this.validationErrors = [];
      for (const rule of this.rules) {
        const error = rule(this.selected);
        console.log(typeof error, "error")
        if (typeof error === 'string') {
          this.validationErrors.push(error);
          return false
        }
      }
      return true;
    },
    resetValidation() {
      this.validationErrors = []
    },
    openDialog() {
      this.dialog.title = this.$t('general.add')
      this.dialog.show = true
    }
  }
}
</script>

<style scoped>

</style>
