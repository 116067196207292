<template>
  <div style="width: 100%; height: 100%" class="d-flex justify-center align-center flex-column">
    <v-card class="d-flex justify-center align-center flex-column px-16 pb-16">
      <div class="d-flex align-center justify-center mb-3" style="border-radius: 1px">
        <v-img :src="require('../assets/montezuma_purple.svg')"
               width="300"
        />
      </div>

      <div v-if="loading">
        <div class="d-flex justify-center my-4">
          <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
          />
        </div>

        <div class="text-h6 font-weight-medium mt-5">
          {{$t(translation + '.loadingData')}}
        </div>
      </div>
      <div v-else>
        <div v-if="message === ''">
          <div class="font-weight-bold">
            {{user}}
          </div>
          <div class="mb-2 ">
            {{ $t(this.translation + '.resetPasswordMsg') }}
          </div>
          <v-form  ref="form" class="ma-0 pa-0 mt-4" style="width: 100%">
            <v-text-field
                class="ma-0 pa-0"
                v-model="password"
                :label="$t(translation + '.password') + ' *'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                :rules="rules.required"
                autocomplete="off"
                outlined
                dense
            />
            <v-text-field
                class="ma-0 pa-0"
                v-model="confirm_password"
                :label="$t(translation + '.confirm_password') + ' *'"
                :append-icon="confirmShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="confirmShowPassword ? 'text' : 'password'"
                @click:append="confirmShowPassword = !confirmShowPassword"
                :rules="rules.passwordNotSame"
                autocomplete="off"
                outlined
                dense
            />
            <div class="mb-2" style="width: 100%">
              <div class="caption mt-1">{{ $t('general.validationMsg') }}</div>
            </div>
            <v-btn
                color="primary"
                block
                :loading="loadingSubmit"
                @click="changePassword()"
            >
              {{ $t(translation + '.confirm') }}
            </v-btn>
          </v-form>
        </div>
        <div v-else>
          <div class="d-flex justify-center">
            <v-icon class="my-3" size="120" :color="iconColor">
              {{icon}}
            </v-icon>
          </div>

          <div class="text-h6 font-weight-medium mt-5">
            {{message}}
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import axios from "axios"
import {mapMutations} from "vuex";

export default {
  name: "ResetPassword",
  data() {
    return {
      translation: "reset_password",
      user: '',
      password: '',
      confirm_password: '',
      confirmShowPassword: false,
      showPassword: false,
      loading: false,
      loadingSubmit: false,
      token: '',
      message: '',
      icon: '',
      iconColor: '',
      rules: {
        required: [v => !!v || this.$t(this.translation + '.field_required')],
        passwordNotSame: [v => this.password === v || this.$t(this.translation + '.passwordNotSame')]
      }
    }
  },
  created() {
    const token = this.$route.params.token;
    if (token) {
      this.checkToken(token)
    }
  },
  methods: {
    ...mapMutations("snackbar", {
      setSnackbar: "setSnackbar"
    }),
    checkToken(token) {
      this.loading = true

        axios.post('/api/check-uuid', {
          token
        }).then((response) => {
          this.user = response.data.full_name
          this.token = token
          this.loading = false
        }).catch((error) => {
          this.loading = false
          if(error.response && error.response.data) {
            this.iconColor = "red"
            this.icon = "mdi-alert-circle"
            this.message = error.response.data.message
          }
        })
    },
    changePassword() {

      if (!this.$refs.form.validate()) {
        return
      }

      if(this.token === ''){
        this.setSnackbar({color: 'red', text: this.$t(this.translation + '.tokenNotValid')})
      }

      this.loadingSubmit = true

      axios.post('/api/set-new-password', {
        token: this.token,
        new_password: this.password
      }).then((response) => {
        if(response.data && response.data.message) {
          this.icon = "mdi-check-circle-outline"
          this.iconColor = "success"
          this.message = response.data.message
        }
        this.password = ''
        this.confirm_password = ''
        this.token = ''
        this.user = ''
        this.loadingSubmit = false
      }).catch((error) => {
        this.loadingSubmit = false

        if (error.response && error.response.data) {
          this.setSnackbar({color: "red", text: error.response.data.message})
          return
        }
      })
    }
  }
}
</script>


<style scoped>

</style>