<template>
  <div class="ma-0 pa-0">
    <v-chip
        class="ma-0 pa-0 d-flex flex-row justify-center align-center"
        color="primary"
        outlined
        link
        @click="showCredits()"
    >
      <v-icon
          class="ma-0 pa-0 pr-1"
          color="primary"
      >
        mdi-star-four-points-circle
      </v-icon>
      <span
          class="font-weight-bold ma-0 pa-0"
      >
          {{item.credit === null ? "0.00" : item.credit}}
          </span>
    </v-chip>
    <universal-dialog
        v-if="dialogStatus.show"
        :show-dialog="dialogStatus.show"
        width="650px"
        @close="closeShowDialog"
    >
      <data-table-pagination
          :title="$t(translation + '.credits')"
          :headers="headers"
          :prop-filter="{credit_for_user_id: [{
            'logic_operator': 'AND',
            'operator' : '=',
            'value' : item.id
          }]}"
          :api-read="dataTablePaginationData.apiRead"
          :append-item="dataTablePaginationData.addItem"
          @success-append="dataTablePaginationData.addItem = null"
      >
        <template v-slot:toolbarAction>
          <v-btn @click="addCredits" color="primary">
            <v-icon dark class="mr-2">mdi-plus</v-icon>
            {{ $t('general.add') }}
          </v-btn>
        </template>
      </data-table-pagination>
      <universal-dialog
          width="320px"
          :title="dialogLeads.title"
          v-if="dialogLeads.show"
          :show-dialog="dialogLeads.show"
          @close="closeCreditsDialog()"
      >
              <div class="ma-0 pa-0 d-flex flex-column align-content-center">
                <div class="d-flex justify-center align-center">
                  <v-icon class="ma-0 pa-0" size="82" color="primary">
                    mdi-star-four-points-circle
                  </v-icon>
                </div>

                <div class="pa-0 ma-0 text-subtitle-1 text-center mt-5">
                  {{$t(translation + '.msgAddCredits')}}
                </div>
                <v-form ref="form" v-on:submit.prevent="confirm()">
                <div class="d-flex align-center justify-center mt-3">
                  <v-text-field
                      id="credits"
                      v-model="credits"
                      :label="$t(translation + '.credits')"
                      class="ma-0 pa-0"
                      :rules="rules.creditsValidation"
                      outlined
                      dense
                  />
                </div>
                  <v-btn
                      block
                      :loading="confirmLoading"
                      color="error"
                      @click="confirm()"
                  >
                    {{$t('general.add')}}
                  </v-btn>
                </v-form>
              </div>
      </universal-dialog>
    </universal-dialog>
  </div>
</template>

<script>
import UniversalDialog from "@/components/UniversalDialog.vue";
import {selectField} from "@/utils/functions";
import {mapMutations} from "vuex";
import {generateHeaders} from "@/utils/functions";

export default  {
  name: "ChangeCredits",
  components: {
    "universal-dialog" : UniversalDialog
  },
  props: {
    item: {}
  },
  data() {
    return {
      translation: "credits",
      dialogStatus: {
        show: false,
        title: ""
      },
      dialogLeads: {
        show: false,
        title: ""
      },
      confirmLoading: false,
      credits: 0,
      rules: {
        creditsValidation: [
            v => /^[-+]?\d+(\.\d+)?$/.test(v) || this.$t(this.translation + '.creditValidationMsg')
        ]
      },
      dataTablePaginationData: {
        apiRead: '/api/lead_transactions/index',
        addItem: null,
      }
    }
  },
  computed: {
    headers() {
      return this.generateHeaders([
        {key: "credit_before_adding", text: this.$t(this.translation + '.credit_before_adding')},
        {key: "add_credit_amount", text: this.$t(this.translation + '.add_credit_amount')},
        {key: "credit_after_adding", text: this.$t(this.translation + '.credit_after_adding')},
        {key: "total_price", text: this.$t(this.translation + '.price')},
        {key: "updated_at", text: this.$t(this.translation + '.date')}
      ])
    }
  },
  methods: {
    ...mapMutations('snackbar', {
      setSnackbar : 'setSnackbar'
    }),
    generateHeaders,
    selectField,
    addCredits() {
      this.dialogLeads.title = this.$t(this.translation + '.credits')
      this.dialogLeads.show = true
      this.selectField('credits')
    },
    showCredits(){
      this.dialogStatus.title = this.$t(this.translation + '.credits')
      this.dialogStatus.show = true
    },
    closeShowDialog() {
      this.dialogStatus.title = ""
      this.dialogStatus.show = false
      this.closeCreditsDialog()
    },
    closeCreditsDialog() {
      this.credits = 0
      this.dialogLeads.title = ""
      this.dialogLeads.show = false
    },
    confirm() {

      if(!this.$refs.form.validate()) {
        return
      }
      this.confirmLoading = true

      this.$axios.post('/api/lead_transactions/store', {
        "credit_amount" : this.credits,
        "price" : this.price,
        "credit_for_user_id" : this.item.id
      }).then((response) => {

        console.log(response.data, "resposne.data confirm")
        this.dataTablePaginationData.addItem = response.data;

        let tempData = {
          item: this.item,
          credits: response.data.credit_after_adding
        }
        this.$emit('change-credits', tempData)

        this.closeCreditsDialog()
        this.setSnackbar({color: "success", text: this.$t(this.translation + '.msgSuccessAddCredits')})
      }).catch((error) => {
        if(error.response && error.response.data){
          this.$swal.fire({
            icon: "error",
            title: this.$t('general.error'),
            text: error.response.data.message
          })
          return
        }
        this.$swal.fire({
          icon: "error",
          title: this.$t('general.error'),
          text: this.$t('general.contact_admin_message')
        })
      }).finally(() => {
        this.confirmLoading = false
      })
    },

  }
}
</script>


<style scoped>

</style>