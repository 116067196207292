<template>
  <div class="text-center mt-3">
    <v-alert
        dark
        class="error"
        icon="mdi-alert-circle-outline"
    >
      {{ errorMessage }}
    </v-alert>
  </div>
</template>
<script>
export default {
  name: 'NoDataComponent',
  props: {
    errorMessage: {},
  },
}
</script>
