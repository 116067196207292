<template>
    <v-app id="inspire">
      <router-view/>
      <v-overlay :value="loading">
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-overlay>
      <universal-snackbar/>
    </v-app>
</template>

<script>
import {mapGetters} from "vuex";
import UniversalSnackbar from "@/components/UniversalSnackbar";

export default {
  components: {
    "universal-snackbar": UniversalSnackbar
  },
  computed: {
    ...mapGetters("auth", {
      loading: "getLoading"
    })
  }
}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

.v-application {
  font-family: Montserrat, sans-serif !important;
  .title {
  font-family: Montserrat, sans-serif !important;
  }
}

#app {
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
