<template>
  <data-table-pagination
      :headers="headers"
      :prop-filter="{
        'ad_id': adId
      }"
      :map-keys="dataTablePaginationData.mapKeys"
      :translation="translation"
      :api-read="dataTablePaginationData.apiRead"
      :disable-cus-template="['coins']"
  />
</template>
<script>
import {mapMutations} from "vuex";
import {
  generateHeaders,
  showSwalPopUpConfirmation
} from "@/utils/functions";

export default {
  name: "HeadsHistory",
  props: {
    adId: {
      default: null,
      required: true
    }
  },
  data() {
    return {
      translation: "leads_history",
      dataTablePaginationData: {
        apiRead: '/api/leads/index',
        mapKeys: {
          'lead_statuses.name': 'lead_statuses',
          'users.first_name': 'users',
          'users.last_name': 'users',
          'services.name': 'services',
        }
      }
    }
  },
  computed: {
    headers() {
      return this.generateHeaders([
        {key: "services.name", text: this.$t(this.translation + '.service')},
        {key: "users.first_name", text: this.$t(this.translation + '.first_name')},
        {key: "users.last_name", text: this.$t(this.translation + '.last_name')},
        {key: "credit", text: this.$t(this.translation + '.credits')},
        {key: "contact_time", text: this.$t(this.translation + '.contact_time')},
        {key: "lead_statuses.name", text: this.$t(this.translation + '.is_accepted')},
        {key: "response_time", text: this.$t(this.translation + '.response_time')}
      ])
    },
  },
  methods: {
    ...mapMutations("snackbar", {
      setSnackbar: "setSnackbar"
    }),
    showSwalPopUpConfirmation,
    generateHeaders,
  }
}
</script>
<style scoped>

</style>