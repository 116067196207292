const initialState = {
    show: false,
    color: 'success',
    text: '',
    timeout: 3000
};

const getters = {
    getSnackbar(state) {
        return state
    }
};

const actions = {};

const mutations = {
    closeSnackbar(state) {
        Object.assign(state, initialState)

        state.show = false
    },
    setSnackbar(state,data) {
        Object.assign(state, initialState)

        const {color, text, timeout} = data;
        if(color) state.color = color;
        if(text) state.text = text;
        if(timeout) state.timeout = timeout;

        state.show = true
    }
};

export default {
    namespaced: true,
    state: {...initialState},
    getters,
    actions,
    mutations,
};
