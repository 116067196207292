<template>
  <v-container fluid grid-list-sm>
    <!--data table-->
    <!--    <data-table-pagination-->
    <!--        :title="$t(translation + '.title')"-->
    <!--        :api-read="dataTablePaginationData.apiRead"-->
    <!--        :translation="dataTablePaginationData.translation"-->
    <!--        :optionsProp="dataTablePaginationData.options"-->
    <!--        :headers="headers"-->
    <!--    >-->
    <data-table-pagination
        :title="$t(translation + '.title')"
        :api-read="dataTablePaginationData.apiRead"
        :translation="dataTablePaginationData.translation"
        :optionsProp="dataTablePaginationData.options"
        :headers="headers"
    >
      <template v-slot:toolbarAction>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="ml-1"
                color="warning"
                v-bind="attrs"
                v-on="on"
                :loading="$refs.dataTablePaginationRef ? $refs.dataTablePaginationRef.$data.tableDataLoading : false"
                :disabled="$refs.dataTablePaginationRef ? $refs.dataTablePaginationRef.$data.tableDataLoading : false"
                @click="$refs.dataTablePaginationRef.refreshData(false, false)"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('tooltips.refresh') }}</span>
        </v-tooltip>
      </template>
      <template v-slot:action-buttons="{item}">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="downloadSeeder(item)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('general.edit') }}</span>
        </v-tooltip>
      </template>
    </data-table-pagination>
  </v-container>
</template>

<script>
/* eslint-disable */
import {
  addEmit,
  editEmit
} from "@/statics/DataTableFunctions";
import { generateHeaders } from "@/utils/functions";
import DataTablePagination from "../../components/DataTablePagination";

export default {
  name: "SeederGenerator",
  components: {
    'data-table-pagination': DataTablePagination
  },
  data() {
    return {
      dataTablePaginationData: {
        translation: 'seeder_generator',
        apiRead: '/api/all_tables/pgsql',
        options: {
          sortBy: [
            'table_name'
          ],
          sortDesc: [
            false
          ]
        },
      },
      apiDownload: 'http://localhost:92/api/seeder_generator',
      translation: 'seeder_generator',
      dataForModal: null,
      addEditItemDialog: false
    }
  },
  methods: {
    generateHeaders,
    addEmit,
    editEmit,
    openAddEditDialog(item) {
      this.addEditItemDialog = true;
      this.dataForModal = item;
    },
    closeAddEditDialog() {
      this.addEditItemDialog = false;
      this.dataForModal = null;
    },
    downloadSeeder(item) {
      window.open(this.apiDownload + "/" + item.table_name);
    }
  },
  computed: {
    headers() {
      return this.generateHeaders([
        {key: "table_name", text: "Naziv tabele"},
        {
          key: "actions",
          text: this.$t('general.actions'),
          align: 'center',
          sortable: false,
        }
      ]);
    },
  },
}
</script>

