import Vue from "vue"
import axios from "axios"

axios.defaults.timeout = 180 * 1000
axios.defaults.withCredentials = true
axios.interceptors.request.use(async (config) => {
    return config
}, (error) => {
    Promise.reject(error)
});


axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error.response && error.response.status === 401) {
            if (window.location.href.includes('login')) {
                return Promise.reject(error); // Propagate refresh error
            }
            try {

                const response = await axios.get('/api/refresh-token-web');

                if (response.data) {
                    return axios(error.config);
                }
            } catch (refreshError) {

                // Handle token refresh failure
                console.error('Token refresh failed:', refreshError);
                // You might want to clear token and redirect to login here
                if (window.location.href.includes('login')) {
                    return Promise.reject(refreshError); // Propagate refresh error
                }
                localStorage.setItem('isAuthenticated', false);
                window.location.href = '/login';

            }
        }
        return Promise.reject(error)
    }
)
Vue.prototype.$axios = axios
