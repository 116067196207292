<template>
<div>
  <v-chip
      label
      @click="item.ad_statuses !== null ? changeStatus() : ''"
      :color="item.ad_statuses ? item.ad_statuses.background_color : 'gray'"
      outlined
      link
  >
    <v-icon class="mr-2">
      mdi-refresh-circle
    </v-icon>
    {{item.ad_statuses?.name}}
  </v-chip>
  <universal-dialog
      v-if="dialogStatus.show"
      :title="dialogStatus.title"
      :show-dialog="dialogStatus.show"
      width="450px"
      @close="closeDialog"
  >
    <template v-slot:dialogActions>
      <v-spacer/>
      <v-btn
          :loading="confirmLoading"
          color="error"
          @click="confirm"
      >
        {{$t('general.confirm')}}
      </v-btn>
    </template>
    <v-form ref="form">
      <v-autocomplete
          class="ma-0 pa-0"
          :label="$t(translation + '.statuses')"
          v-model="status"
          :items="items"
          item-text="name"
          item-value="id"
          :loading="loading"
          autocomplete="off"
          :rules="[ v => !!v || $t('general.required_field')]"
          :no-data-text="loading ? $t('general.loading_msg') : $t('general.no_data')"
          outlined
          dense
      />
      <v-textarea
          class="mt-3"
          :label="$t(translation + '.reason')"
          v-if="status === 3"
          v-model="description"
          name="input-7-1"
          :rules="[ v => !!v || $t(translation + '.reasonRequired')]"
          filled
          outlined
          auto-grow
      />
    </v-form>
  </universal-dialog>
</div>
</template>

<script>
import UniversalDialog from "@/components/UniversalDialog.vue";
import {mapMutations} from "vuex";

export default  {
  name: "ChangeStatus",
  props: {
    item: {}
  },
  components: {
    'universal-dialog' : UniversalDialog
  },
  data() {
    return {
      translation: "adStatus",
      dialogStatus: {
        show: false,
        title: ""
      },
      status: "",
      apiRead: "/api/ad_statuses/statusOrder",
      items: [],
      loading: false,
      confirmLoading: false,
      description: ""
    }
  },
  watch: {
    'dialogStatus.show': function (val) {
      if(val === true) {
        this.getStatuses()
      }
    }
  },
  methods: {
    ...mapMutations("snackbar", {
      setSnackbar: "setSnackbar"
    }),
    getStatuses() {
      this.loading = true
      this.$axios.post('/api/ad_statuses/statusOrder', {
        status_id: this.item.ad_statuses.id,
        is_report: false
      }).then((response) => {
        this.items = response.data.status_order
      }).catch((error) => {
        if(error.response && error.response.data){
          this.$swal.fire({
            icon: "error",
            title: this.$t('general.error'),
            text: error.response.data.message
          })
          return
        }
        this.$swal.fire({
          icon: "error",
          title: this.$t('general.error'),
          text: this.$t('general.contact_admin_message')
        })
      }).finally(() => {
        this.loading = false
      })
    },
    changeStatus() {
      console.log("changeStatus")
      this.dialogStatus.title = this.$t(this.translation + '.changeStatus')
      this.dialogStatus.show = true
    },
    closeDialog() {
      this.dialogStatus.title = ""
      this.dialogStatus.show = false
      this.status = ""
      this.description = ""
    },
    confirm() {
      console.log("test")
      if(!this.$refs.form.validate()){
        return
      }
      this.confirmLoading = true
      this.$axios.put(`/api/jobs/changeStatus/${this.item.id}`, {ad_status_id: this.status, status_comment: this.description}).then((response) => {
        console.log(response.data, "response.data")
        if(response.data) {
          this.$emit('statusChange', response.data)
          this.setSnackbar({color: "success", text: this.$t(this.translation + '.successfulChangedStatus')})
          this.closeDialog()
        }
      }).catch((error) => {
        if(error.response && error.response.data){
          this.$swal.fire({
            icon: "error",
            title: this.$t('general.error'),
            text: error.response.data.message
          })
          return
        }
        this.$swal.fire({
          icon: "error",
          title: this.$t('general.error'),
          text: this.$t('general.contact_admin_message')
        })
      }).finally(() => {
        this.confirmLoading = false
      })
    }
  }
}
</script>


<style scoped>

</style>