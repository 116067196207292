/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store"
import HomeView from '../views/HomeView.vue'
import LoginView from "@/views/LoginView";
import DashView from "@/layout/DashView";
import UsersView from "@/views/UsersView";
import SeederGenerator from "@/views/_admin/SeederGenerator";
import NotFoundView from "@/views/NotFoundView";
import CountriesView from "@/views/CountriesView";
import CitiesView from "@/views/CitiesView";
import UserTypesView from "@/views/UserTypesView";
import TestView from "@/views/TestView";
import ServicesView from "@/views/ServicesView";
import WeekDayNames from "@/views/WeekDaysView";
import AdsView from "@/views/AdsView";
import CurrencyView from "@/views/CurrencyView.vue";
import NotificationsView from "@/views/NotificationsView.vue";
import VerificationAccountView from "@/views/VerificationAccountView.vue";
import ResetPasswordView from "@/views/ResetPasswordView.vue";
import AdsOnApprovalView from "@/views/AdsOnApprovalView.vue";
import LandingView from "@/views/LandingView.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'LandingView',
        component: LandingView,
        meta: {
            requiredAuth: false,
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginView
    },
    {
        path: '/seeders',
        name: 'SeederGenerator',
        component: SeederGenerator,
        meta: {
            requiredAuth: false,
        },
    },
    {
        path: '/verify-account/:token',
        name: "VerifyAccount",
        component: VerificationAccountView,
        meta: {
            requiredAuth: false
        }
    },
    {
        path: '/reset-password/:token',
        name: "ResetPassword",
        component: ResetPasswordView,
        meta: {
            requiredAuth: false
        }
    },
    {
        path: "/",
        component: DashView,
        meta: {
            requiredAuth: true,
        },
        children: [
            {
                path: '/dashboard',
                component: HomeView,
                name: 'Dashboard',
                meta: {
                    requiredAuth: true,
                },
            },
            {
                path: '/user-types',
                component: UserTypesView,
                name: 'UsersTypes',
                meta: {
                    requiredAuth: true,
                },
            },
            {
                path: '/test',
                component: TestView,
                name: 'Test',
                meta: {
                    requiredAuth: true,
                },
            },
            {
                path: '/users',
                component: UsersView,
                name: 'Users',
                meta: {
                    requiredAuth: true,
                },
            },
            {
                path: '/countries',
                component: CountriesView,
                name: 'Countries',
                meta: {
                    requiredAuth: true,
                },
            },
            {
                path: '/cities',
                component: CitiesView,
                name: 'Cities',
                meta: {
                    requiredAuth: true,
                },
            },
            {
                path: '/services',
                component: ServicesView,
                name: 'Services',
                meta: {
                    requiredAuth: true,
                },
            },
            // {
            //     path: '/days',
            //     component: WeekDayNames,
            //     name: 'WeekDayNames',
            //     meta: {
            //         requiredAuth: true,
            //     },
            // },
            {
                path: '/ads',
                component: AdsView,
                name: 'Ads',
                meta: {
                    requiredAuth: true,
                },
            },
            {
                path: '/currencies',
                component: CurrencyView,
                name: 'CurrencyView',
                meta: {
                    requiredAuth: true,
                },
            },{
                path: '/notifications',
                component: NotificationsView,
                name: 'NotificationsView',
                meta: {
                    requiredAuth: true,
                },
            },
            {
                path: '/ads-on-approval',
                component: AdsOnApprovalView,
                name: 'AdsOnApprovalView',
                meta: {
                    requiredAuth: true
                }
            }
        ]
    },
    {
        path: '*',
        name: 'NotFound',
        component: NotFoundView
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    // da li ima auth routa
    if (to.meta.requiredAuth) {
        // pozivamo vuex da pokupimo podatke o korisniku
        let user = store.getters["auth/getUserData"]
        // setujemo flag u localStorage kad loginamo korisnika
        const isAuthenticated = localStorage.getItem("isAuthenticated");
        // ako nemamo podatke o korisniku
        if (user.id === null && isAuthenticated) {
            // pozovi api da dobijemo podatke o korisniku
            await store.dispatch("auth/userProfile");
            // pozovi vuex da dobijemo te podatke
            user = store.getters["auth/getUserData"];
        }
        // ako ne dobijemo uspjesno te podatke vrati korisnika na login u suprotnom prebaci ga na zeljenu stranicu
        if (user.id === null) {
            localStorage.setItem("isAuthenticated", false)
            next({path: "/login"})
        } else {
            next();
        }
    } else {
        next()
    }


});

export default router
