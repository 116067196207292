<template>
  <div style="width: 100%; height: 100%" class="d-flex justify-center align-center flex-column">
      <v-card class="d-flex justify-center align-center flex-column px-16 pb-16">
        <div class="d-flex align-center justify-center mb-3" style="border-radius: 1px">
          <v-img :src="require('../assets/montezuma_purple.svg')"
            width="300"
          />
        </div>
          <v-progress-circular
              v-if="loading"
              :size="50"
              color="primary"
              indeterminate
          />
          <div v-else>
            <v-icon size="120" :color="iconColor">
              {{ icon }}
            </v-icon>
          </div>
        <div v-if="title !== ''" class="text-h5 font-weight-medium text-center mt-5">
          {{ title }}
        </div>
      </v-card>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: "VerificationAccountView",
  data() {
    return {
      translation: 'verify_account',
      icon: 'info',
      title: '',
      loading: true,
      iconColor: 'green'
    };
  },
  created() {
    const token = this.$route.params.token;
    if (token) {
      this.verifyAccount(token);
    } else {
      this.title = this.$t(this.translation + '.general_error');
      this.icon = 'mdi-alert-circle';
      this.iconColor = 'red'
      this.loading = false;
    }
  },
  methods: {
    verifyAccount(token) {

      this.loading = true
      this.message = this.$t(this.translation + '.loading_msg')

      axios.post('/api/verify-account', {token})
          .then(() => {
            this.loading = false
            this.title = this.$t(this.translation + '.success_msg')
            this.icon = 'mdi-check-circle-outline';
            this.iconColor = 'green'
          })
          .catch((error) => {
            if(error.response && error.response.data) {
              this.title = error.response.data.message
            } else {
              this.title = this.$t(this.translation + '.error_msg')
            }
            this.loading = false
            this.icon = 'mdi-alert-circle';
            this.iconColor = 'red'
          })
    }
  }
}
</script>

<style scoped>
.verification-container {
  margin-top: 50px;
}

.v-card-title {
  font-size: 1.25rem;
  font-weight: 500;
}

.v-btn {
  font-size: 1rem;
  font-weight: 500;
}
</style>