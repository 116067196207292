<template>
  <v-form ref="form" v-on:submit.prevent>
    <v-row>
      <v-col class="ma-0 pa-0 px-1 gaptest" v-for="(field, index) in fields" :cols="field.cols" :key="index">
        <v-btn
          v-if="field.type === 'button'"
          color="primary"
          @click="field.function"
          block
        >
          {{field.name}}
        </v-btn>

        <!--   REGULAR TEXT-FIELD     -->
        <v-text-field
            :id="'field_'+(index+1)"
            v-model="form[field.model]"
            v-if="field.type === 'text-field'"
            :label="field.name + `${field.rules.length > 0 ? ' *' : ''}`"
            :hide-details="field.hide_details"
            :rules="field.rules"
            v-money="field.money ? moneyFloat : null"
            v-mask="field.mask"
            outlined
            dense
        />
        <!--   TEXTAREA     -->
        <v-textarea
            v-if="field.type === 'text-area'"
            :ref="field.model"
            :id="'field_'+(index+1)"
            :label="field.name + `${field.rules.length > 0 ? ' *' : ''}`"
            v-model="form[field.model]"
            :hide-details="field.hide_details"
            :rules="field.rules"
            name="input-7-1"
            filled
            outlined
            auto-grow
        />
        <!--   TREE VIEW SELECT -->
        <tree-view-select
          v-if="field.type === 'tree-view-select'"
          :id="'field_'+(index + 1)"
          :label="field.name + `${field.rules.length > 0 ? ' *' : ''}`"
          v-model="form[field.model]"
          :api-read="field.api_read"
          :translation="field.translation"
        />
        <table-picker
            :id="'field_'+(index+1)"
            v-if="field.type === 'table-picker'"
            v-model="form[field.model]"
            :label="field.name + `${field.rules.length > 0 ? ' *' : ''}`"
            :hide-details="field.hide_details"
            :rules="field.rules"
            :api-read="field.api_read"
            :single-select="field.single_select"
            :table-header="field.headers"
            :item-value="field.itemValue"
            :item-text="field.itemText"
        />
        <!--    INIFINTY AUTOCOMPLETE    -->
        <virtual-scroll
            :id="'field_'+(index+1)"
            v-if="field.type === 'virtual'"
            v-model="form[field.model]"
            :label="field.name + `${field.rules.length > 0 ? ' *' : ''}`"
            :item-value="field.item_value"
            :item-text="field.item_text"
            :api-read="field.api_read"
            :multiple="field.multiple"
            :rules="field.rules"
            :options-api="field.options"
            @auto-select="handleAutoSelect"
        />
        <!--   DATEPICKER     -->
        <v-menu
            v-if="field.type === 'datepicker'"
            :id="'field_'+(index+1)"
            v-model="dateMenu[field.model]"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="form[field.model]"
                :label="field.name + `${field.rules.length > 0 ? ' *' : ''}`"
                :hide-details="field.hide_details"
                outlined
                dense
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            />
          </template>
          <v-date-picker
              v-model="form[field.model]"
              @input="dateMenu[field.model] = false"
          />
        </v-menu>
        <!--   CHECKBOX     -->
        <v-checkbox
            :id="'field_'+(index+1)"
            v-model="form[field.model]"
            v-if="field.type === 'checkbox'"
            :label="field.name + `${field.rules.length > 0 ? ' *' : ''}`"
            :rules="field.rules"
            :hide-details="field.hide_details"
            dense
        />
      </v-col>
    </v-row>
    <div class="caption">{{$t('general.validationMsg')}}</div>
  </v-form>
</template>

<script>
import { setFocus } from "@/utils/functions";
import VirtualAutocomplete from "@/components/VirtualAutocomplete";
import TablePicker from "@/components/TablePicker";
import TreeViewSelect from "@/components/TreeViewSelect";
export default {
  name: "UniversalForm",
  components: {
    'virtual-scroll': VirtualAutocomplete,
    'table-picker' : TablePicker,
    'tree-view-select' : TreeViewSelect
  },
  props: {
    fields: {
      default: () => [],
      type: Array
    },
    editItem: {
      default: null
    }
  },
  data() {
    return {
      form: {},
      dateMenu: {},
      moneyFloat: {
        decimal: '.',
        thousands: '',
        prefix: '€',
        suffix: '',
        precision: 2,
        masked: true /* doesn't work with directive */
      },
    }
  },
  created() {

    this.formFillFields(this.editItem)
    this.setFocus('field_1')
  },
  mounted() {
    if(!this.editItem){
      this.startFields()
    }
    console.log(this.form, "this.form")
  },
  watch: {
    editItem: function (val) {
      if (val !== null) {
        this.formFillFields(val)
      }
    }
  },
  methods: {
    getData(){
      if (!this.$refs.form.validate()) {
        return null
      }
      this.$emit('submit-form', this.form)
    },
    handleAutoSelect(val) {
      console.log(val, "handleAutoSelect")
    },
    startFields() {
      this.fields.map((item) => {
        if(item.type === 'checkbox'){
          this.form[item.model] = false
        } else {
          this.form[item.model] = ''
        }
      })
    },
    setFocus,
    formFillFields(val) {
      if (val !== null) {
        for (const key in val) {
          this.form[key] = val[key]
        }
      }
    }
  }
}
</script>

<style scoped>
.gaptest {
  gap: 1em;
}
</style>
