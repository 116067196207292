<template>
  <div class="ma-0 pa-0">
    <v-form ref="form" v-on:submit.prevent class="ma-0 pa-0">
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0 px-1 gaptest" cols="12">
          <v-text-field
              v-model="form.subject"
              :label="$t(translation + '.title') + ' *'"
              :rules="[validationRules.required]"
              outlined
              dense
          />
        </v-col>
        <v-col class="ma-0 pa-0 px-1 gaptest" cols="12">
          <v-textarea
              v-model="form.notification_text"
              :label="$t(translation + '.body') + ' *'"
              :rules="[validationRules.required]"
              outlined
              dense
          />
        </v-col>
        <v-col cols="12">
          <v-tabs v-model="tab" background-color="primary" grow @change='form.notification_for_users = []; form.notification_user_types = []'>
            <v-tab key="groups" class="white--text" style="border-right: 2px solid red">{{$t(translation + '.user_roles')}}</v-tab>
            <v-tab key="users" class="white--text">{{$t(translation + '.users')}}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item
                key="groups"
                class="pa-2"
            >
              <table-picker
                  ref="userTypeTablePicker"
                  v-model="form.notification_user_types"
                  :label="$t(translation + '.user_types')"
                  api-read="/api/user_types/index_active"
                  :single-select="false"
                  :table-header="rolesHeaders"
                  item-value="id"
                  item-text="name"
              />

            </v-tab-item>
            <v-tab-item
                key="users"
                class="pa-2"

            >
              <table-picker
                  ref="userTablePicker"
                  v-model="form.notification_for_users"
                  :label="$t(translation + '.users')"
                  api-read="/api/users/index"
                  :single-select="false"
                  :table-header="userHeaders"
                  item-value="id"
                  item-text="full_name"
                  width-dialog="1000px"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-form>
    <div class="caption mt-3">{{ $t('general.validationMsg') }}</div>

  </div>
</template>

<script>
import {validationRules} from "@/utils/validationRules";
import TablePicker from "@/components/TablePicker.vue";

export default {
  name: "AddNotification",
  components: {
    "table-picker": TablePicker
  },
  data() {
    return {
      translation: "notification",
      rolesHeaders: [
        {key: "name", text: "Naziv"}
      ],
      tab: null,
      radioGroup: 1,
      form: {
        subject: "",
        notification_text: "",
        notification_user_types: [],
        notification_for_users: []
      }
    }
  },
  computed: {
    validationRules() {
      return validationRules
    },
    userHeaders() {
      return [
        {key: "first_name", text: this.$t(this.translation + '.user_headers.first_name')},
        {key: "last_name", text: this.$t(this.translation + '.user_headers.last_name')},
        {key: "username", text: this.$t(this.translation + '.user_headers.username')},
        {key: "email", text: this.$t(this.translation + '.user_headers.email')},
        {key: "phone", text: this.$t(this.translation + '.user_headers.phone')},
      ]
    }
  },
  methods: {
    async getData() {

      if (!this.$refs.form.validate()) {
        return null
      }
      return JSON.parse(JSON.stringify(this.form))
    },
  }

}
</script>

<style scoped>
.v-tab--active{
  color: #be1e2d !important;
  font-weight: bold;
}
</style>