<template>
  <v-menu
      ref="menu"
      :close-on-content-click="false"
      :return-value.sync="time"
      v-model="menuVisible"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
          v-model="time"
          :label="label"
          append-icon="mdi-clock-time-four-outline"
          @click:append="menuVisible = !menuVisible"
          readonly
          outlined
          :disabled="disabled"
          dense
          hide-details
          v-on="on"
          style="max-width: 150px;"
      />
    </template>
    <v-time-picker
        v-if="menuVisible"
        v-model="time"
        :disabled="disabled"
        :min="min"
        :max="max"
        full-width
        format="24hr"
        @click:minute="$refs.menu.save(time)"
    />
  </v-menu>
</template>

<script>
export default {
  name: "TimePicker",
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      required: true
    },
    timeCurrent: {},
    disabled: {
      default:false
    },
    day: {},
    min: {},
    max: {}
  },
  data() {
    return {
      time: this.value,
      menuVisible: false
    }
  },
  watch: {
    value: function (newValue) {
      this.time = newValue
    },
    time: function (val) {
      this.$emit('input', val)
    },
    timeCurrent: function (val) {
      console.log("timeCurrent", val)
    }
  },
  methods: {
    saveTime() {
      this.$emit('input', this.time)
      this.menuVisible = false
    }
  }
}
</script>

<style scoped>

</style>
