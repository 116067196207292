
<template>
      <div class="wrapper">
        <v-card max-width="400" elevation="24">
          <v-card-title class="d-flex justify-center primary">
            <img :src="require('../assets/montezuma.svg')" height="40%" width="40%" alt="User Icon"/>

          </v-card-title>
          <v-card-text>
            <form @submit.prevent="login" >
              <v-text-field
                  id="username"
                  @keydown.enter="login"
                  v-model="username"
                  filled
                  class="pl-3 pr-3"
                  :label="$t(translation + '.username')"
                  required/>
              <v-text-field
                  @keydown.enter="login"
                  v-model="password"
                  filled
                  class="pl-3 pr-3 pb-0"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  hint="Minimum 8 karaktera"
                  @click:append="showPassword = !showPassword"
                  :label="$t(translation + '.password')"
                  required/>
              <v-btn
                  block
                  class="mb-3"
                  :loading="loading"
                  color="primary"
                  dark
                  @click="login"
              >
                {{$t(translation + '.login')}}
              </v-btn>
              <!--                <v-progress-circular v-if="loading" indeterminate color="primary"/>-->
              <v-alert :value="error == '' ? false : true" color="red" class="white--text">
                {{this.error}}
              </v-alert>
            </form>
          </v-card-text>
        <!-- Login Form -->

        </v-card>

      </div>
</template>

<script>

import { setFocus } from "../utils/functions"
export default {
  name: "LoginView",
  data() {
    return {
      username: '',
      password: '',
      loading: false,
      showPassword: false,
      error: "",
      translation: "login"
    }
  },
  created() {
    this.setFocus("username");
  },
  // computed: {
  //   primaryColor() {
  //     return this.$vuetify.theme.themes.light.primary;
  //   }
  // },
  methods: {
    setFocus,
    login() {
      console.log("login")
      this.loading = true
      this.$axios.post('/api/loginWeb', {
        "is_web" : true,
        "username" : this.username,
        "password" : this.password
      }).then(({data}) => {
        // console.log(response.data, "response.data")
        // this.$store.commit('auth/setLoginStatus', "success")
        if(data) {
          const user = data.user;
          localStorage.setItem("isAuthenticated", true);
          this.$store.commit('auth/setUser', {
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone: user.phone,
            details: user.details,
            image_url: user.image_url
          })

          this.$router.push('/ads-on-approval')
        }
      }).catch((error) => {
        console.log(error.response.data.error, "message")
        this.error = error.response.data.error
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.wrapper {
  padding: 0;
  margin: 0;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  justify-content: center !important;
  height: 100vh !important;
  width: 100vw !important;
  background-color: #443062;
}
</style>
