<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title class="headline">
        {{ $t(translation + '.title') }}
      </v-toolbar-title>
      <v-spacer/>
      <slot name="toolbarAction">
      </slot>
    </v-toolbar>
    <v-sheet class="pa-2 primary" style="background-color: #2b2d41">
      <v-text-field
          v-if="!showErrorMessage"
          ref="searchData"
          @keyup.enter.native="filterData"
          solo
          dark
          flat
          prepend-inner-icon="mdi-magnify"
          solo-inverted
          hide-details
          @click:clear="search = ''"
          clearable
          clear-icon="mdi-close-circle-outline"
          :label="this.$t('general.search')"
      />
    </v-sheet>
    <v-layout
        align-center
        justify-center
        column
        fill-height
        fill-width
    >
      <v-progress-circular
          class="my-5"
          v-if="showLoading"
          :size="70"
          color="primary"
          indeterminate
      />

    </v-layout>
    <div
        v-if="!items || items.length === 0"
        class="text-center"
    >
      <no-data v-if="!showLoading" :error-message="errorMessage"/>
    </div>
    <!-- ### TREE VIEW ### -->
    <v-treeview
        v-if="!showLoading"
        dense
        :items="treeItems"
        item-children="services"
        :search="search"
        :filter="filter"
        item-key="id"
        :open="open"
        class="pa-3"
        activatable
        @update:active="addItem"
    >

      <template v-slot:no-data>
        <div class="text-xs-center mx-5">
          <v-alert color="error" icon="mdi-alert-circle-outline">
            {{ errorMessage }}
          </v-alert>
        </div>
      </template>
      <template slot="label" slot-scope="{ item,  active }">
        <v-row align="center" class="my-0">
          <span
              v-if="selectable"
              class="ml-7"
          >
                    <v-checkbox
                        class="ma-0 pa-0"
                        v-if="item.is_service"
                        hide-details
                        :value="item['selected']"
                    />
          </span>
          <slot
              class="cursor"
              name="tree-body"
              :item="item"
              :active="active"
              @click="addItem"
          >
          </slot>
        </v-row>
      </template>
    </v-treeview>
  </v-card>
</template>

<script>
export default {
  name: "UniversalTreeView",
  props: {
    translation: {
      default: "",
      type: String,
    },
    id: {
      default: "",
      type: String,
    },
    childName: {
      default: "",
      type: String
    },
    items: {
      default: () => [],
      type: Array
    },
    selectable: {
      default: false,
      type: Boolean
    },
    showLoading: {
      default: false,
      type: Boolean
    },
    multipleSelect: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      search: null,
      open: [],
      active: [],
      treeItems: [],
      errorMessage: this.$t('general.no_data'),
      showErrorMessage: false,
      selection: []
    }
  },
  computed: {
    filter() {
      if (this.search === '') {
        this.open = [];
      }
      return (item, search) => {
        let searchValue = item['name'];
        // if (item['is_service']) {
        //   searchValue += item['file_name'];
        // }
        if (searchValue && searchValue.toLowerCase().includes(search.toLowerCase())) {
          const scId = item['id'];
          // console.log(scId)
          if (!item['is_service']) {
            // console.log('push')
            this.open.push(scId);
          }
          console.log('open', scId);
          this.openParentItem(scId, null);
          return true;
        }
        return false;
      }
    },
  },
  watch: {
    items: function (val) {
      if (val) {
        this.treeItems = this.items
      }
    }
  },
  mounted() {
    this.treeItems = this.items
    this.setSelectedFields(this.items, 'is_service')
  },
  methods: {
    /** F-ja koju pozivamo na ref */
    getData() {
      console.log(this.selection, "this.selection")
      if(this.multipleSelect === false) {
        return this.selection[0]
      } else {
        return this.selection
      }
    },

    /** F-ja koja nam dodaje key selected=false na iteme gdje je is_service vezano za sleketrovanje*/
    setSelectedFields(nodes, key) {
      for (let node of nodes) {
        if (node[key]) {
          node.selected = false
        }
        if (node.services) {
          this.updateNode(node.services, key)
        }
      }
    },

    /**
     * F-ja kojoj predajemo niz i item i mijenjamo aktivno da ne vezano za selektovanje
     * @param nodes
     * @param data
     * @returns {*}
     */

    updateNode(nodes, data) {
      for (let node of nodes) {
        if (node.id === data.id) {
          node.selected = !node.selected
        }
        if (node.services) {
          this.updateNode(node.services, data)
        }
      }
      return nodes
    },

    /**
     * F-ja koju pozivamo na enter koja nam pokrece pretragu u tree view
     */

    filterData() {
      this.search = this.$refs.searchData.lazyValue
    },
     findItemById(items, id) {
      for (let item of items) {
        if (item.id === id) {
          return item;
        }
        if (item.services && item.services.length > 0) {
          const foundItem = this.findItemById(item.services, id);
          if (foundItem) {
            return foundItem;
          }
        }
      }
      return null;
    },
    /**
     * F-ja kojoj predajemo item i pratimo istoriju dodanih
     * napraveljno samo za single select kad dodamo drugi item
     * u niz brise se prethodni
     * @param item
     */

    addItem(item) {

      console.log(item, "item - adDItem")
      let itemData = this.findItemById(this.treeItems, item[0])
      let tItem = null
      // proveravamo da li je usluga
      if(!itemData?.is_service){
        return
      }

      // radimo toogle slected key-a
      tItem = {...itemData, selected : !itemData.selected}


      if(this.selection.length > 0) {
        // // ako postoji makni iz niza
        this.selection = this.selection.filter(tItem => tItem.id !== itemData.id)
      }


      console.log(tItem, "item boolena")
      if (tItem.selected) {
        this.selection.push(tItem)

        if(this.multipleSelect) {
          console.log("prolazi")
          console.log(tItem, "this.selection[0]")
          this.treeItems = this.updateNode(this.treeItems, tItem)
          return;
        }

        // proveravamo da li niz ima 2 elementa
        if (this.selection.length > 1 && this.multipleSelect === false) {
          this.treeItems = this.updateNode(this.treeItems, this.selection[0])
          this.selection.shift()
        }
      }
    },

    /**
     * F-ja napraveljena samo za uslusge
     * todo: treba prilagoditi da ne samo za usluge da radi
     * @param id
     * @param item
     */

    openParentItem(id, item) {
      if (item == null) {
        this.treeItems.forEach(temp => {
          this.openParentItem(id, temp)
        })
      } else {
        if (!item.services) {
          return;
        }
        item.services.forEach(child => {
          if (child.id === id) {
            if (!this.open.includes(child.service_id)) {
              this.open.push(child.service_id)
              this.openParentItem(child.service_id, null)
            }
          } else {
            this.openParentItem(id, child)
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
