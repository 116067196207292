<template>
  <div class="ma-0 pa-0">
    <v-form ref="form" v-on:submit.prevent class="ma-0 pa-0">
      <v-row class="ma-0 pa-0">
        <!--     Uloge   -->
        <v-col class="ma-0 pa-0 px-1 gaptest mt-2" cols="12">
          <table-picker
              ref="tablePicker"
              v-model="form.roles"
              :label="$t(translation + '.roles')"
              :rules="[validationRules.arrayRequired]"
              api-read="/api/user_types/index_active"
              :single-select="false"
              :table-header="rolesHeaders"
              item-value="id"
              item-text="name"
          />
        </v-col>
        <!--    ime    -->
        <v-col class="ma-0 pa-0 px-1 gaptest" cols="6">
          <v-text-field
              v-model="form.first_name"
              :label="$t(translation + '.first_name') + ' *'"
              :rules="[validationRules.required]"
              outlined
              dense
          />
        </v-col>
        <!--    Prezime    -->

        <v-col class="ma-0 pa-0 px-1 gaptest" cols="6">
          <v-text-field
              v-model="form.last_name"
              :label="$t(translation + '.last_name') + ' *'"
              :rules="[validationRules.required]"
              outlined
              dense
          />
        </v-col>

        <!--    email    -->
        <v-col class="ma-0 pa-0 px-1 gaptest mt-2" cols="6">
          <v-text-field
              v-model="form.email"
              :label="$t(translation + '.email') + ' *'"
              :rules="[validationRules.email]"
              outlined
              dense
          />
        </v-col>
        <!--    username   -->

        <v-col class="ma-0 pa-0 px-1 gaptest mt-2" cols="6">
          <v-text-field
              v-model="form.username"
              :label="$t(translation + '.username') + ' *'"
              :rules="[validationRules.required]"
              outlined
              dense
          />
        </v-col>

        <v-col v-if="editItem !== null" class="ma-0 pa-0 px-1" cols="12">
          <v-checkbox
              class="ma-0 pa-0"
              v-if="editItem !== null"
              label="Promijeni lozinku"
              v-model="show_password"
              hide-details
          />
        </v-col>
        <!--    sifru    -->
        <v-col v-if="show_password" class="ma-0 pa-0 px-1 gaptest mt-2" cols="6">
          <v-text-field
              v-model="form.password"
              :label="$t(translation + '.password') + ' *'"
              append-icon=""
              :rules="[validationRules.required]"
              outlined
              dense
          />
        </v-col>

        <!--    Potvrdi sifru    -->
        <v-col v-if="show_password" class="ma-0 pa-0 px-1 gaptest mt-2" cols="6">
          <v-text-field
              v-model="form.confirm_password"
              :label="$t(translation + '.confirm_password')"
              :rules="[v => v === form.password || $t(translation + '.passwordDoesNotMatch')]"
              outlined
              dense
          />
        </v-col>
        <!--    Broj telefona    -->

        <v-col class="ma-0 pa-0 px-1 gaptest mt-2" cols="6">
          <v-text-field
              v-model="form.phone"
              :label="$t(translation + '.phone') + ' *'"
              :rules="[validationRules.required]"
              outlined
              dense
          />
        </v-col>
        <!--    OPIS    -->
        <v-col class="ma-0 pa-0 px-1 gaptest mt-2" cols="12">
          <v-textarea
              :label="$t(translation + '.details')"
              v-model="form.details"
              name="input-7-1"
              filled
              outlined
              auto-grow
          />
        </v-col>

        <v-col class="ma-0 pa-0 px-1 flex-row elevation-3" cols="12">
          <file-pond
              class="pa-2"
              ref="file-pond"
              :label="$t(translation + '.dragImageHere')"
              v-model="form.image_file"
              :allow-multiple="false"
          />
        </v-col>
      </v-row>
    </v-form>
    <div class="caption mt-3">{{$t('general.validationMsg')}}</div>

  </div>
</template>

<script>
/* eslint-disable */

import TablePicker from "@/components/TablePicker.vue";
import {validationRules} from "@/utils/validationRules";
import FilePond from "@/components/FilePond.vue";

export default {
  name: "AddEditUser",
  props: {
    editItem: {
      default: null
    },
    translation: {}
  },
  components: {
    "table-picker": TablePicker,
    "file-pond": FilePond
  },
  data() {
    return {
      dragover: false,
      uploadedFiles: [],
      rolesHeaders: [
        {key: "name", text: "Naziv"}
      ],
      rules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      form: {
        roles: [],
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        password: "",
        confirm_password: "",
        phone: "",
        details: "",
        image_file: ""
      },
      show_password: true,
      imageUrl: null
    }
  },
  computed: {
    validationRules() {
      return validationRules
    }
  },
  created() {
    if (this.editItem) {
      this.fillForm()
    }
  },
  methods: {
    // this.form.photos = this.editItem.ad_photos != null ? this.editItem.ad_photos.map((item) => {
    //
    //   let newUrl = item.
    //   return newUrl;
    //
    // }) : []
    fillForm() {
      this.form.details = this.editItem.details
      this.form.email = this.editItem.email
      this.form.first_name = this.editItem.first_name
      this.form.id = this.editItem.id
      this.form.last_name = this.editItem.last_name
      this.form.phone = this.editItem.phone
      this.form.username = this.editItem.username
      this.form.roles = this.editItem.roles

      this.form.image_file = this.editItem.photo_url === '' ? [] : [this.editItem.photo_url.replace('/user_images', '/api/image/user_images')]
      this.show_password = false
    },
    async getData() {


      const validatePicker = this.$refs.tablePicker.validate()

      console.log(validatePicker, "validatePicker")


      if(!this.$refs.form.validate() || validatePicker === false) {
        return null
      }

      let formData = new FormData()


      const getImageFile = this.$refs["file-pond"].getPondFiles()

      if(getImageFile.length > 0) {
        formData.append('image_file', getImageFile[0].file)
      }

      for (const key in this.form) {
        if (key === 'image_file') {
          continue
        }

        // ako je uncekirana izmjena sifre onda ne salji polje password
        if(this.show_password === false && key === 'password') {
          continue
        }

        // roles moramo strinify jer je niz u backend radimo json_decode
        if (key === "roles") {
          formData.append(key, JSON.stringify(this.form[key]))
          continue
        }
        formData.append(key, this.form[key])
      }
      // // formData.append('jsonObject', new Blob([jsonString], { type: 'application/json'}))
      // formData.forEach((value, key) => {
      //   console.log(`${key}: ${value}`);
      // });
      return formData
    },
  }
}

</script>


<style scoped>

</style>