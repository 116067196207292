import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import authModule from './module/auth'
import snackbarModule from "./module/snackbar";
export default new Vuex.Store({
  state: {
    // loginStatus: ""
  },
  getters: {
  },
  mutations: {
    // setLoginStatus(state,data) {
    //   state.loginStatus = data;
    // }
  },
  actions: {
  },
  modules: {
    auth: authModule,
    snackbar: snackbarModule
  }
})
