<template>
  <ads-view
      :title="$t(translation + '.ads_on_approval')"
      :props-headers="tableHeader"
      :props-filter="{
          'ad_statuses': 'Čeka na odobrenje'
      }"
  />
</template>

<script>

import AdsView from "@/views/AdsView.vue";

export default {
  name: "AdsOnApprovalView",
  components: {
    "ads-view" : AdsView
  },
  data(){
    return {
      translation : "ads",
    }
  },
  computed: {
    tableHeader: function () {
      return [
        {key: "updated_at", text: this.$t(this.translation + '.updated_at')},
        {key: "title", text: this.$t(this.translation + '.name')},
        {key: "users.first_name",  text: this.$t(this.translation + '.user_name'), operator: 'LIKE'},
        {key: "users.last_name", text: this.$t(this.translation + '.user_last_name'), operator: 'LIKE'},
        {key: "price_from", text: this.$t(this.translation + '.price_from'), operator: '>='},
        {key: "price_to", text: this.$t(this.translation + '.price_to'), operator: '<='},
        // {key: "description", text: this.$t(this.translation + '.description')},
        {key: "ad_statuses.name", text: this.$t(this.translation + '.status')},
        {key: "is_active", text: this.$t(this.translation + '.active')},
        {key: "actions", text: this.$t('general.actions'), sortable: false}
      ]
    }
  }
}
</script>

<style scoped>

</style>