<template>
  <div>
    <data-table-pagination
        ref="dataTable"
        :title="$t(translation + '.title')"
        :headers="headers"
        :translation="translation"
        :append-item="dataTablePaginationData.addItem"
        :edit-item="dataTablePaginationData.editItem"
        :delete-item="dataTablePaginationData.deleteItem"
        :api-read="dataTablePaginationData.apiRead"
        :def-active-object="activeObject"
        :prop-sort-by="dataTablePaginationData.sortBy"
        @success-delete="dataTablePaginationData.deleteItem = null"
        @success-append="dataTablePaginationData.addItem = null"
        @success-edit="dataTablePaginationData.editItem = null"

    >

      <template v-slot:toolbarAction>
        <v-btn @click="addItem" color="primary">
          <v-icon dark class="mr-2">mdi-account-plus</v-icon>
          {{ $t('general.add') }}
        </v-btn>

        <v-btn class="ml-2" color="warning" @click="$refs.dataTable.resetData()">
          <v-icon dark>mdi-refresh</v-icon>
        </v-btn>
      </template>
      <template v-slot:action-buttons="{item}">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="warning"
                @click="editItem(item)"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('general.edit') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="error"
                icon
                @click="deleteItem(item)"
            >
              <v-icon>{{ item.is_active ? 'mdi-delete' : 'mdi-backup-restore' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ item.is_active ? $t('general.delete') : $t('general.return') }}</span>
        </v-tooltip>
      </template>
    </data-table-pagination>

    <universal-dialog
        v-if="dialog.show"
        :title="dialog.title"
        :show-dialog="dialog.show"
        width="650px"
        @close="closeDialog"
    >
      <add-edit-user ref="addEditUser" :translation="translation" :edit-item="form.item"/>
      <template v-slot:dialogActions>
        <v-sheet class="text-end" width="100%">
          <v-spacer/>
          <v-btn @click="closeDialog" :disabled="loading" color="error">
            {{ $t('general.cancel') }}
          </v-btn>
          <v-btn class="ml-5" @click="getFormData" :loading="loading" color="primary">
            {{ form.item !== null ? $t('general.edit') : $t('general.add') }}
          </v-btn>
        </v-sheet>
      </template>
    </universal-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import DataTablePagination from "@/components/DataTablePagination";
import UniversalDialog from "@/components/UniversalDialog";
import {CRUD_VARS} from "@/utils/consts";
import {mapMutations} from "vuex";
import {
  addItem,
  closeDialog,
  deleteItem,
  editItem,
  generateHeaders,
  handleSubmitData,
  showSwalPopUpConfirmation
} from "@/utils/functions";
import AddEditUser from "@/components/Users/AddEditUser.vue";

export default {
  name: "UsersView",
  components: {
    "data-table-pagination": DataTablePagination,
    "universal-dialog": UniversalDialog,
    "add-edit-user": AddEditUser
  },
  data() {
    return {
      translation: "users",
      loading: false,
      deleteLoadingId: -1,
      dataTablePaginationData: {
        apiRead: '/api/users/index',
        sortBy: ['updated_at'],
        addItem: null,
        editItem: null,
        deleteItem: null
      },
      form: {
        item: ""
      },
      activeObject: [
        {value: "", text: this.$t('general.all')},
        {value: 'true', text: this.$t('general.active')},
        {value: 'false', text: this.$t('general.not_active')},
      ],
      ...CRUD_VARS
    }
  },
  computed: {
    headers() {
      return this.generateHeaders([
        {key: "updated_at", text: this.$t(this.translation + '.updated_at')},
        {key: "first_name", text: this.$t(this.translation + '.first_name')},
        {key: "last_name", text: this.$t(this.translation + '.last_name')},
        {key: "email", text: this.$t(this.translation + '.email')},
        {key: "username", text: this.$t(this.translation + '.username')},
        {key: "phone", text: this.$t(this.translation + '.phone')},
        {key: "details", text: this.$t(this.translation + '.details')},
        {key: "credit", text: this.$t(this.translation + '.credits')},
        {key: "user_types", text: this.$t(this.translation + '.roles')},
        {key: "is_active", text: this.$t(this.translation + '.active')},
        {key: "actions", text: this.$t('general.actions'), sortable: false}
      ])
    }
  },
  created() {
    this.apiStore = "/api/users/store"
    this.apiUpdate = "/api/users/update"
    this.apiDelete = "/api/users/destroy"
    this.sucMessageEdit = this.$t(this.translation + '.sucMessageEdit')
    this.sucMessageStore = this.$t(this.translation + '.sucMessageStore')
    this.sucMessageDelete = this.$t(this.translation + '.sucMessageDelete')
    this.sucMessageReturn = this.$t(this.translation + '.sucMessageReturn')

  },
  methods: {
    ...mapMutations("snackbar", {
      setSnackbar: "setSnackbar"
    }),
    closeDialog,
    addItem,
    showSwalPopUpConfirmation,
    generateHeaders,
    editItem,
    deleteItem,
    handleSubmitData,
    async getFormData() {
      this.loading = true
      const data = await this.$refs.addEditUser.getData()

      if (data === null) {
        this.loading = false
        return
      }

      try {
        this.handleSubmitData(data)
      } catch (e) {
        console.log(e, "eeee")
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
