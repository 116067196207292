<template>
  <div style="width: 100%; height: 100%; margin: 0; padding: 0;">
    <div class="container">
      <header class="d-flex">
        <span class="d-flex align-center justify-center">
          <v-img :src="require('../assets/montezuma_purple.svg')" width="170px" height="60px"/>
        </span>
      </header>
      <section class="my-10">
        <v-row>
          <v-col class="d-flex flex-column justify-center">
            <div class="text-h3 font-weight-bold">
              Lorem Ipsum is simply dummy text .
            </div>
            <div class="text-h5 mt-10">
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
              when an unknown printer took a galley of type and scrambled it to make a type specimen book.
              It has survived not only five centuries, but also the leap into electronic typesetting,
              remaining essentially unchanged. It was popularised in the 1960s with the release of
              Letraset sheets containing Lorem Ipsum passages
            </div>
            <div>
              <v-img
                  src="https://static.vecteezy.com/system/resources/previews/026/775/604/non_2x/set-of-buttons-google-play-app-store-appgallery-free-vector.jpg"
                  width="500px"
              />
            </div>
          </v-col>
          <v-col class="d-flex justify-center">
            <v-img
                max-width="300px"
                max-height="650px"
                :src="require('../assets/login_screen.png')"
            />
          </v-col>
        </v-row>
      </section>
      <section class="d-flex justify-center my-10">
        <div style="width: 60%">
          <div class="text-h3 text-center font-weight-bold my-10">
            Lorem Ipsum is simply dummy text.
          </div>
          <div class="text-h6 text-center">
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
            when an unknown printer took a galley of type and scrambled it to make a type specimen book.
            It has survived not only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages
          </div>
        </div>
      </section>
      <section class="my-10 py-10">
        <v-row>
          <v-col cols="12" lg="4">
            <div class="d-flex flex-column align-center">

              <v-icon size="56">
                mdi-numeric-1-circle-outline
              </v-icon>
              <div class="font-weight-bold my-5">

                Lorem ipsum
              </div>
              <div class="text-center">

                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                when an unknown printer
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="4">
            <div class="d-flex flex-column align-center">

              <v-icon size="56">

                mdi-numeric-2-circle-outline
              </v-icon>
              <div class="font-weight-bold my-5">
                Lorem ipsum
              </div>
              <div class="text-center">

                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                when an unknown printer
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="4">
            <div class="d-flex flex-column align-center">
              <v-icon size="56">

                mdi-numeric-3-circle-outline
              </v-icon>
              <div class="font-weight-bold my-5">

                Lorem ipsum
              </div>
              <div class="text-center">
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                when an unknown printer
              </div>
            </div>
          </v-col>
        </v-row>
      </section>
      <section class="my-10 py-10">
        <v-row>
          <v-col class="d-flex flex-column justify-center">
            <div class="text-h3 font-weight-bold">
              Lorem Ipsum is simply dummy text .
            </div>
            <div class="text-h5 mt-10">
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
              when an unknown printer took a galley of type and scrambled it to make a type specimen book.
              It has survived not only five centuries, but also the leap into electronic typesetting,
              remaining essentially unchanged. It was popularised in the 1960s with the release of
              Letraset sheets containing Lorem Ipsum passages
            </div>
          </v-col>
          <v-col class="d-flex justify-center">
            <v-img
                max-width="300px"
                max-height="650px"
                :src="require('../assets/home_screen.png')"
            />
          </v-col>
        </v-row>
      </section>
      <footer class="footer pt-5">
        <v-row>
          <v-col>
            <div class="footer-section">
              <h3>Kontaktirajte nas</h3>
              <ul>
                <li>Email: info@montezuma.com</li>
                <li>Phone: +38267111111</li>
              </ul>
            </div>
            <div class="footer-section">
              <h3>O nama</h3>
              <p>Lorem Ipsum has been the industry's standard dummy text ever since </p>
            </div>
          </v-col>
          <v-col class="d-flex flex-column align-end">
            <div class="footer-section">
              <h3>Zapratite nas</h3>
              <ul class="social-icons">
                <li>
                  <v-icon color="white">mdi-facebook</v-icon>
                </li>
                <li>
                  <v-icon color="white">mdi-twitter</v-icon>
                </li>
                <li>
                  <v-icon color="white">mdi-instagram</v-icon>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
        <div class="text-center">
          <p>&copy; 2024 Montezuma. Sva prava zadržana.</p>
        </div>
      </footer>
    </div>
  </div>

</template>

<script>
export default {
  name: "LandingView",
  data() {
    return {}
  },
  methods: {
    downloadApp() {
      // alert('Redirect to download page or app store');
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin: 0 auto;
  max-width: 1200px;
  background-color: #fff;
}
.border-test {
  border: 1px solid red;
}
.footer {
  background-color: #2b2b41;
  color: #fff;
}

.footer-section {
  flex: 1;
  margin: 0 20px;
}

.footer-section h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
}

.social-icons {
  display: flex;
  padding: 0;
}

.social-icons li {
  margin-right: 10px;
}

.social-icons a {
  color: #fff;
  font-size: 20px;
}

</style>