<template>
  <v-container fluid style="height: 100%">
    <v-layout class="d-flex" align-center justify-center style="height: 100%">
        <v-card class="pa-5">
          <div class="d-flex align-center justify-center mb-3" style="border-radius: 1px">
            <v-img :src="require('../assets/montezuma_purple.svg')"
                   width="300"
            />
          </div>
          <v-layout class="my-10" align-center justify-center>
            <v-icon size="72" color="red">
              mdi-information
            </v-icon>
          </v-layout>
          <div class="text-h6 text-center mb-3">{{$t(translation + '.pageDoesNotExist')}}</div>
          <v-layout align-center justify-center>
            <v-btn color="primary" href="/">{{$t(translation + '.home')}}</v-btn>
          </v-layout>
        </v-card>
    </v-layout>
  </v-container>
</template>

<script>
export default  {
  name: "NotFoundView",
  data() {
    return {
      translation: "not_found"
    }
  }
}
</script>