<template>
  <div>
    <v-toolbar v-if="title !== ''" dense class="ma-0 pa-0">
      <v-toolbar-title class="font-weight-bold ma-0 pa-0">
        {{ title }}
      </v-toolbar-title>
      <v-spacer class="ma-0 pa-0"/>
      <slot name="toolbarAction"/>
    </v-toolbar>
    <v-data-table
        dense
        v-model="selected"
        :mobile-breakpoint="breakpoint"
        :page="page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="tableData"
        :options.sync="options"
        :server-items-length="totalRows"
        :footer-props="{
          itemsPerPageOptions: itemsPerPageOptions,
          'items-per-page-text': '',
          'page-text': fromItem + '-' + toItem + ' ' + $t('general.of') + ' ' + totalRows
        }"
        :loading-text="$t('general.loading_data')"
        class="elevation-1 ma-0 pa-0"
        :loading="tableDataLoading"
        :show-select="singleSelect != null"
        :single-select="singleSelect"
        item-key="id"
        @click:row="rowClick"
    >
      <template v-slot:[`header.data-table-select`]/>
      <template v-slot:[`item.is_active`]="{ item }">
        <v-chip small
                :class="{'error inactive-row white--text':(!item.is_active) , 'success white--text':(item.is_active)}">
          {{ item.is_active ? $t(translation + '.active') : $t(translation + '.inactive') }}
        </v-chip>
      </template>
      <template v-if="!disableCusTemplate.includes('coins')" v-slot:[`item.credit`]="{item}">
        <div v-if="item.user_types.filter(tItem => tItem?.id !== 1).length > 0">
          <change-credits :item="item" @change-credits="handleChangeCredits"/>
        </div>
      </template>
      <!-------------------------------------  DETAILS EDIT DELETE  ----------------------------------------------->
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex justify-center">
          <slot name="action-buttons" :item="item"></slot>
        </div>
      </template>
      <template v-slot:[`item.ad_statuses.name`]="{item}">
          <change-status :item="item" @statusChange="handleChangedStatus"/>
      </template>
      <template v-slot:[`item.user_types`]="{item}">
        <v-chip v-if="item.user_types.length === 0"
                class="font-weight-bold text-uppercase"
                small
        >
          {{ $t('users.noRoles') }}
        </v-chip>
        <v-chip
            v-else
            v-for="(role, index) in item.user_types"
            class="font-weight-bold text-uppercase"
            style="margin-top: 1px; margin-bottom: 1px"
            color="primary"
            :key="index"
            small
        >
          {{ typeof role === 'object' ? role.name : role }}
        </v-chip>

      </template>
      <!----------------------------------- ## FILTERS ## ------------------------------------>
      <template v-slot:[`body.prepend`]>
        <tr class="py-0 px-2 my-0 accent white--text" style="height: 60px !important;">
          <th
              v-for="(header, index) in headers"
              :key="header.name"
              :colspan="singleSelect != null && index == 0 ? 2 : 1"
          >
            <v-select
                v-if="header.name === 'is_active'"
                class="justify-center pt-2 mt-0"
                dense
                hide-details
                v-model="filters[header.value]"
                @change="refreshData(true, false)"
                :items="activeObjects"
                item-value="value"
                item-text="text"
                style="font-size: inherit; text-align: right !important;"
            />
            <v-text-field
                v-else-if="header.name !== 'actions'"
                v-model="filters[header.value]"
                @keyup.enter.native="refreshData(true, false)"
                :placeholder="$t('general.filter') + ' ' + header.text"
                filled
                dense
                height="10px"
                hide-details
                style="font-size: inherit;"
                class="ma-0 pa-0 primary--text"
                enterkeyhint="done"
            />
            <v-layout
                v-else class="justify-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      depressed
                      small
                      v-bind="attrs"
                      v-on="on"
                      :disabled="tableDataLoading"
                      color="primary"
                      @click="refreshData(true, false)">
                    <v-icon>mdi-search-web</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('general.search') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      depressed
                      class="ml-1"
                      small
                      v-bind="attrs"
                      v-on="on"
                      :disabled="tableDataLoading"
                      color="error"
                      @click="refreshData(true, true)">
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('general.clear') }}</span>
              </v-tooltip>
            </v-layout>
          </th>
        </tr>
      </template>
      <!-------------------------------- ## NO DATA ## ----------------------------------->
      <template v-slot:no-data>
        <no-data-component :error-message="errorMessage"/>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import {readTableDataPostPagination} from "@/utils/functions";
import NoDataComponent from "@/components/NoDataComponent";
import ChangeStatus from "@/components/Ads/ChangeStatus.vue";
import ChangeCredits from "@/components/Users/ChangeCredits.vue";

export default {
  name: "DataTablePagination",
  components: {
    'no-data-component': NoDataComponent,
    'change-status': ChangeStatus,
    'change-credits': ChangeCredits
  },
  props: {
    title: {
      default: '',
      type: String,
    },
    headers: {
      default: () => [],
      type: Array,
      required: true
    },
    translation: {
      default: null,
      type: String,
    },
    apiRead: {
      default: null,
      type: String,
      required: true
    },
    iconDetailsDialog: {
      default: 'mdi-card-account-details-outline',
      type: String,
      required: false
    },
    additionalParameter: {
      default: null,
      type: Object,
      required: false,
    },
    breakpoint: {
      default: 0,
      type: Number
    },
    appendItem: {
      default: null,
    },
    editItem: {
      default: null,
    },
    deleteItem: {
      default: null,
    },
    singleSelect: { // ako je null ne prikazi select ako je false onda je multi select i ako je true onda je signle select
      default: null
    },
    propFilter: {
      default: () => {
      },
      type: Object
    },
    propSelected: {
      default: () => [],
      type: Array
    },
    disableCusTemplate: {
      default: () => [],
      type: Array
    },
    itemsPerPageOptions: {
      default() {
        return [
          10,
          20,
          30,
          50,
          100
        ]
      },
      type: Array
    },
    mapKeys: {
      default: null,
      type: Object
    },
    propDesc: {
      default: () => [true]
    },
    propSortBy: {
      default: () => []
    },
    defActiveObject: {
      default: () => []
    }
  },
  data() {
    return {
      tableDataLoading: false,
      deleteLoadingId: -1,
      totalRows: 0,
      page: 1,
      numberOfPages: 1,
      itemsPerPage: 0,
      tableData: [],
      errorMessage: this.$t('general.no_data'),
      filters: {
        is_active: ''
      },
      options: {
        sortDesc: this.propDesc,
        sortBy: this.propSortBy
      },
      reportDownloading: false,
      selected: JSON.parse(JSON.stringify(this.propSelected)),
    }
  },
  // created() {
  //   this.selected = JSON.parse(JSON.stringify(this.propSelected))
  // },
  methods: {
    handleChangeCredits(data) {
      console.log(data, "data emit")
      this.tableData = this.tableData.map((item) => {
        if (item.id === data.item.id) {
          item.credit = data.credits
        }
        return item
      })
    },
    rowClick(item, row) {

      // slucaj ako ocemo da nema selecta
      if (this.singleSelect === null) {
        return
      }
      row.select(!row.isSelected);

      if (this.singleSelect === true) {
        //ako je prazan niz dodaj item u niz
        if (this.selected.length === 0) {
          this.selected.push(item)
        } else {
          if (this.selected.find(tItem => tItem.id === item.id)) {
            this.selected = this.selected.filter(tItem => tItem.id !== item.id)
          } else {
            this.selected = []
            this.selected.push(item)
          }
        }
      } else {
        if (this.selected.find(tItem => tItem.id === item.id)) {
          this.selected = this.selected.filter(tItem => tItem.id !== item.id)
        } else {
          this.selected.push(item)
        }
      }
    },
    getSelectedData() {
      console.log(!!this.singleSelect, this.singleSelect, "this.single")
      return this.selected
    },
    readTableDataPostPagination,
    refreshData(resetPagination = false, resetFilters = false, isReport = false) {
      if (resetPagination) {
        this.options.page = 1;
        this.options.itemsPerPage = this.itemsPerPageOptions[0];
        this.isRefreshingData = true;
      }

      let tempFilters = JSON.parse(JSON.stringify(this.filters))
      const tempOptions = JSON.parse(JSON.stringify(this.options))

      Object.keys(tempFilters).forEach((item) => {
        const find = this.headers.filter((header) => header.name === item)

        if (find.length > 0) {
          if (find[0].operator) {
            if (tempFilters[item] !== '') {
              tempFilters[item] = [{
                logic_operator: 'AND',
                operator: find[0].operator,
                value: find[0].operator === '>' || find[0].operator === '<' || find[0].operator === '=' ? parseFloat(tempFilters[item]) : tempFilters[item]
              }]
            }
          }
        }

        if (this.mapKeys) {
          Object.keys(this.mapKeys).forEach(key => {
            if (item === key) {
              const previousName = item;
              const value = tempFilters[item];
              item = item.replace(key, this.mapKeys[key]);
              delete tempFilters[previousName];
              tempFilters[item] = value;
            }
          })
        }

      })

      if (this.mapKeys) {
        Object.keys(this.mapKeys).forEach(key => {
          if (tempOptions && tempOptions.sortBy.length > 0 && tempOptions.sortBy[0] === key) {
            tempOptions.sortBy[0] = tempOptions.sortBy[0].replace(key, this.mapKeys[key]);
          }
        })
      }

      if (resetFilters) {
        Object.keys(this.filters).forEach(f => {
          this.filters[f] = ''
        });
        this.filters = {...this.filters}
        tempFilters = this.filters
      }
      let additionalParameters = {
        filters: this.propFilter && Object.keys(this.propFilter).length == 0 ? tempFilters : {...tempFilters, ...this.propFilter},
        options: tempOptions,
      };
      // console.log('additionalParameter',this.additionalParameter);
      if (this.additionalParameter) {
        additionalParameters = {
          ...additionalParameters,
          ...this.additionalParameter
        }
      }
      this.readTableDataPostPagination(
          this.apiRead,
          false,
          null,
          additionalParameters,
          isReport
      )
    },
    resetData() {
      this.refreshData(true, true)
    },
    handleChangedStatus(item) {
      this.handleEditItem(item)
    },
    handleEditItem(item) {
      this.tableData = this.tableData.map((tItem) => {
        if (item.id == tItem.id) {
          return item
        }
        return tItem
      })
      this.$emit('success-edit')
    }
  },
  computed: {
    activeObjects() {
      return this.defActiveObject.length > 0 ? this.defActiveObject : [
        {value: "", text: this.$t('general.all')},
        {value: 'true', text: this.$t(this.translation + '.active')},
        {value: 'false', text: this.$t(this.translation + '.inactive')},
      ]
    },
    fromItem() {
      return this.options.page === 1 ? 1 : (this.options.page - 1) * this.options.itemsPerPage + 1
    },
    toItem() {
      if (this.options.itemsPerPage > this.totalRows) {
        return this.totalRows;
      }
      if (this.options.page === 1) {
        return this.options.itemsPerPage
      }

      const toItems = this.options.page * this.options.itemsPerPage;

      if (toItems > this.totalRows) {
        return this.totalRows;
      }

      return toItems;
    }
  },
  watch: {
    options: {
      handler() {
        if (!this.isRefreshingData) {
          this.refreshData()
        }
      },
      deep: true
    },
    appendItem: function (val) {
      if (val === null) {
        return
      }
      this.tableData = [val, ...this.tableData]
      this.totalRows++;
      this.$emit('success-append')
    },
    editItem: function (val) {
      if (val === null) {
        return
      }
      this.handleEditItem(val)
    },
    deleteItem: function (val) {
      if (val === null) {
        return
      }
      this.tableData = this.tableData.map((item) => {
        if (val.id == item.id) {
          return val
        }
        return item
      })
      this.$emit('success-delete')
    }
  }
}
</script>
<style>
</style>
