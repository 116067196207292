export const CRUD_VARS = {
    dialog: {
        show: false,
        title: ""
    },
    apiStore: "",
    apiUpdate: "",
    apiDelete: '',
    sucMessageEdit: "",
    sucMessageStore: "",
    sucMessageDelete: "",
    sucMessageReturn: "",
}
