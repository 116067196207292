<template>
  <div>
    <div>
<!--      <v-text-field-->
<!--          class="ma-0 pa-0"-->
<!--          outlined-->
<!--          dense-->
<!--          v-model="itemText"-->
<!--          :label="label"-->
<!--          append-icon="mdi-magnify"-->
<!--          @click:append="openDialog"-->
<!--          @click="openDialog"-->
<!--          readonly-->
<!--      />-->
      <v-select
          class="ma-0 pa-0"
          outlined
          :label="label + `${rules.length > 0 ? ' *' : ''}`"
          append-icon="mdi-magnify"
          @click:append="disabled ? '' : openDialog()"
          @click="disabled ? '' :openDialog()"
          dense
          readonly
          :disabled="disabled"
          :loading="showLoading"
          :items="selectItems"
          v-model="selected"
          multiple
          :rules="rules"
          :error-messages="validationErrors"
          small-chips
      >
        <template v-slot:selection="{ attrs, item, select, selected }">
          <v-chip
              class="ma-1"
              color="primary"
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="remove(item)"
          >
            <strong>{{ item.name }}</strong>
          </v-chip>
        </template>
      </v-select>
    </div>
    <universal-dialog
        v-if="dialog.show"
        :title="dialog.title"
        :show-dialog="dialog.show"
        @close="closeDialog"
    >
      <template v-slot:headerAction>
        <v-btn
            dark
            color="secondary"
            @click="confirmSelection"
        >
          {{ $t('general.confirm') }}
        </v-btn>
      </template>
      <tree-view
          ref="treeView"
          :translation="translation"
          :show-loading="showLoading"
          :items="items"
          :selectable="true"
          :multiple-select="multipleSelect"
      >
        <template v-slot:tree-body="{item}">
          <v-row align="center" class="my-0 d-flex flex-row">
            <v-col cols="1" class="ma-0 pa-0">
              <v-icon class="mx-2" :color="!item.is_active ? 'error' : ''">
                {{ !item.is_service ? 'mdi-folder' : 'mdi-tools' }}
              </v-icon>
            </v-col>
            <v-col cols="10" class="ma-0 pa-0">
                <span
                    :class="item.is_active ? 'ml-3' : 'red--text ml-3'"
                    style=" max-width: 70%; white-space: normal; word-break: break-word;">
              {{ item.name }}
            </span>
            </v-col>
<!--            <span class="mx-3 ml-7">-->

<!--          </span>-->

          </v-row>
        </template>
      </tree-view>
    </universal-dialog>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

import {showSwalPopUpConfirmation, closeDialog} from "@/utils/functions";

import UniversalTreeView from "@/components/UniversalTreeView";
import UniversalDialog from "@/components/UniversalDialog";

export default {
  name: "HomeView",
  components: {
    "tree-view": UniversalTreeView,
    "universal-dialog": UniversalDialog,
  },
  props: {
    value: {
      required: true,
    },
    apiRead: { // api obavezan vraca nam iteme za tree-view
      default: "",
      type: String,
      required: true
    },
    translation: {
      default: "",
      type: String
    },
    label: { // label za selection
      default: "",
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      default: () => []
    },
    multipleSelect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: {
        show: false,
        title: ""
      },
      items: [],
      selectItems: this.value,
      showLoading: false,
      addLoading: false,
      selected: this.value,
      validationErrors: [],
    }
  },
  watch: {
    // dio vezan za v-model ako ocemo da napravimo na child komponent
    value: function (newValue) {
      this.validationErrors = []
      this.selected = newValue
      this.selectItems = newValue

    },
    selected: function (val) {
      // this.validationErrors = [];
      this.$emit('input', val)
    }
  },
  computed: {
    // todo: srediti da sa prop izabere koji ce key od objekta koristi
    itemText: function () {
      return this.selected?.name
    }
  },
  created() {
    // pozivamo iteme za tree-view
    if(this.selected.length > 0) {

      this.getData()
    }
  },
  methods: {
    ...mapMutations("snackbar", {
      setSnackbar: "setSnackbar"
    }),
    remove (item) {
      this.selected = this.selected.filter((tItem) => tItem.id !== item.id)
      this.selectItems = this.selectItems.filter((tItem) => tItem.id !== item.id)
    },
    validate() {
      this.validationErrors = [];
      for (const rule of this.rules) {
        const error = rule(this.selected);
        if (typeof error === 'string') {
          
          this.validationErrors.push(error);
          return false
        }
      }
      return true;
    },
    openDialog() {
      this.dialog.title = this.$t('general.chose')
      this.dialog.show = true
      this.getData()
    },
    resetValidation() {
      this.validationErrors = [];
    },
    itemExists(array, item){
      return array.some(existingItem => existingItem.id === item.id);
    },
    confirmSelection() {
      const items = this.$refs.treeView.getData()
      this.selectItems = items
      this.selected = this.selectItems;
      console.log(this.selected, "this.selected")
      this.closeDialog()
    },
    findNode(nodes, data) {
      for (let node of nodes) {
        if (node.id === data) {
          // console.log(node, "node")
          return node
        }
        if (node.services) {
          const foundNode = this.findNode(node.services, data)
          if(foundNode) {
            return foundNode
          }
        }
      }
    },
    getData() {
      this.showLoading = true
      this.$axios.post(this.apiRead, {
        is_report: false,
        is_single_level: false,
        show_inactive_items: false
      }).then((response) => {
        if(response.data) {
          this.items = response.data

          // gledamo ako je selektovan item setuj v-select i tree view
          // ovaj dio vazi samo kad se pokrene komponenta
          if(this.selected) {
            // trazimo dodatne podatke jer dobijamo samo id
            const temp = this.findNode(response.data, this.selected)
            this.selectItems.push(temp)
          }
        }
      }).catch((error) => {
        if(error.response && error.response.data){
          this.$swal.fire({
            icon: "error",
            title: this.$t('general.error'),
            text: error.response.data.message
          })
          return
        }
        this.$swal.fire({
          icon: "error",
          title: this.$t('general.error'),
          text: this.$t('general.contact_admin_message')
        })
      }).finally(() => {
        this.showLoading = false
      })
    },
    showSwalPopUpConfirmation,
    closeDialog,
  }
}
</script>

<style scoped>

</style>
