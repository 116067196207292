<template>
  <v-snackbar
      class="ma-0 pa-0"
      v-model="snackbar.show"
      :bottom=true
      :color="snackbar.color"
      :timeout="snackbar.timeout">
    <span>
          {{ snackbar.text }}
    </span>
    <v-btn text @click="handleCloseSnackbar">
        <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "UniversalSnackbar",

  computed: {
    ...mapGetters("snackbar", {
      snackbar: "getSnackbar"
    })
  },
  methods: {
    ...mapMutations("snackbar", {
      closeSnackbar : "closeSnackbar"
    }),
    handleCloseSnackbar() {
      this.closeSnackbar()
    }
  }
}
</script>

<style scoped>

</style>
