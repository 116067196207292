<template>
    <v-card>

      <v-card-text class="pt-6">

        <v-sheet class="my-5 d-flex align-center justify-center" v-if="loading">
          <v-progress-circular
              indeterminate
              color="primary"
              size="64"
          />
        </v-sheet>
        <v-card v-else elevation="0">

          <v-list-item-group>
            <v-list-item style="border: 1px solid #443062; border-radius: 5px" class="my-1" v-for="day in days" :key="day.id">
              <v-list-item-action class="flex-row align-start justify-center">
                <v-switch
                    :label="day.active ? $t(translation + '.working') : $t(translation + '.not_working')"
                    v-model="day.active"
                    inset
                />
              </v-list-item-action>
              <v-list-item-title style="max-width: 100px;" class="font-weight-bold">
                {{day.name}}
              </v-list-item-title>
              <v-list-item-content class="d-flex flex-row align-center justify-center" style="width: 100%">
                <div v-if="day.active">
                  <div :class="{'d-flex flex-col': true, 'mt-2': index > 0}" style="max-width: 400px; column-gap: 15px" v-for="(time, index) in day.times" :key="index">
                    <v-row class="ma-0 pa-0">
                      <v-col class="ma-0 pa-0" cols="5">
                        <time-picker :label="$t(translation + '.from_time')" v-model="time.start_at" :min="index > 0 ? day.times[index-1].stop_at : null" :disabled="index > 0 ? !!day.times[index-1].stop_at ? false : true : false"/>
                      </v-col>
                      <v-col class="ma-0 pa-0" cols="5">
                        <time-picker :label="$t(translation + '.to_time')" v-model="time.stop_at" :disabled="!time.start_at" :min="addMinutesToTime(time.start_at, 5)"/>
                      </v-col>
                      <v-col cols="2" class="ma-0 pa-0 d-flex" >
                        <v-btn
                            icon
                            outlined
                            class="pa-0 ma-0 d-flex align-center justify-center"
                            @click="removeAdditionShift(day, index)"
                        >
                          <v-icon
                              class="ma-0 pa-0"
                              color="error"
                          >
                            mdi-trash-can
                          </v-icon>
                        </v-btn>
                        <v-btn
                            v-if="day.times.length-1 === index"
                            :disabled="time.start_at == '' && time.stop_at == ''"
                            icon
                            outlined
                            class="pa-0 ma-0 ml-1 d-flex align-center justify-center"
                            @click="addAdditionalShift(day)"
                        >
                          <v-icon
                              class="ma-0 pa-0"
                              color="primary"
                          >
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <div v-if="!day.active" class="d-flex align-center justify-center text-body-1 font-weight-bold text-uppercase">
                  {{$t(translation + '.free_days')}}
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-card>
      </v-card-text>
    </v-card>
</template>

<script>
import TimePicker from "@/components/TimePicker";

export default {
  name: "BusinessHour",
  components: {
    'time-picker' : TimePicker
  },
  props: {
    showDialog: {
      default: true,
      type: Boolean
    },
    persistent: {
      type: Boolean,
      default: false
    },
    data: {
      default: ''
    }
  },
  data() {
    return {
      translation: "business-hours",
      days: [],
      loading: false,
      time: null,
      menu2: false
    }
  },
  created() {
    this.getData()
    console.log(this.addMinutesToTime('08:59', 5), "Test time")
  },
  methods: {
    /**
     * Predajemo vrijeme {time} kao string '08:00' i dodajemo minute {tMinutes} i vraca nam novo vrijeme
     * @param time
     * @param tMinutes
     * @returns {string}
     */
    addMinutesToTime(time, tMinutes) {
      const [hours, minutes] = time.split(':').map(Number);
      const date = new Date();
      date.setHours(hours, minutes + tMinutes, 0, 0);
      return date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit'})
    },
    removeAdditionShift(day, index) {

      this.days = this.days.map((item) => {
        if(day.id === item.id) {
          if(item.times.length === 1) {
            item.times = [{
              id: 0,
              start_at: '',
              stop_at: ''
            }]
          } else {
            item.times = item.times.filter((tItem, tIndex) => tIndex !== index)
          }
        }
        return item
      })
    },
    addAdditionalShift(day) {
      this.days = this.days.map((item) => {
        if(item.id == day.id) {
          item.times.push({id: item.times.length,start_at: '', stop_at: ''})
        }
        return item
      })
    },
    getData() {
      this.loading = true
      this.$axios.post('/api/days/index_active', {
        is_report: false
      }).then((response) => {
        this.days = response.data.map((item) => {
          item.active = false
          return {
            ...item,
            times: [
              {
                id: 0,
                start_at: "",
                stop_at: ""
              }
            ]
          }
        })

        if(this.data) {
          this.data.forEach(item => {
            let index = this.days.findIndex(tItem => tItem.id === item.day_id)

            if(index !== -1) {
              console.log(this.days[index], "this.days")
              this.days[index].active = true

              // ako nisu izabrana vremena setuj start_at: '' i stop_at: '
              if(item.times.length === 0) {
                this.days[index].times = [{start_at: '', stop_at: ''}]
              } else {
                // u suprotnom dodaj vremena
                this.days[index].times = item.times
              }
            }
          })
        }


      }).catch((error) => {
        if(error.response && error.response.data){
          this.$swal.fire({
            icon: "error",
            title: this.$t('general.error'),
            text: error.response.data.message
          })
          return
        }
        this.$swal.fire({
          icon: "error",
          title: this.$t('general.error'),
          text: this.$t('general.contact_admin_message')
        })
      }).finally(() => {
        this.loading = false
      })
    },
    getWorkingDays() {
      return this.days.filter(item => item.active).map((item) => {
        item.day_id =  item.id
        return item
      })
    },
    // confirm() {
    //   console.log(this.days, "this.days")
    //   const parseDates = this.days.filter(item => item.active)
    //   console.log(parseDates, "parseDates")
    //   this.$emit('submit', parseDates)
    // },
    // closeDialog() {
    //   this.$emit('close')
    // }
  }
}
</script>

<style scoped>

</style>
