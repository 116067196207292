<template>
  <v-app id="inspire" class="ma-0 pa-0">
    <v-navigation-drawer
        v-model="drawer"
        class="px-0 py-0 primary"
        app
    >
      <div
          class="primary py-4"
      >
        <v-img :src="require('../assets/montezuma.svg')"/>
      </div>
      <v-divider/>
      <v-treeview
          class="pa-0 ma-0 treeview-custom"
          v-model="selectedItems"
          :items="links"
          :search="search"
          :filter="filter"
          :open.sync="open"
          item-children="items"
          item-text="name"
          item-key="id"
          hoverable
          dense
          open-on-click
      >
        <template
            slot="label"
            slot-scope="{ item }"
        >
          <v-list class="ma-0 pa-0">
            <v-list-item
                :class="{'d-flex align-center ma-0 pa-0': true, 'secondary white--text' : activeLink === item.route }"
                style="cursor: pointer; max-height: 50px"
                @click="goToLink(item.route)">
              <v-icon class="ma-0" color="white">
                {{ item.icon }}
              </v-icon>
              <div style="white-space: pre-wrap;"
                   :class="{'py-0 pl-1 white--text  text-left font-weight-bold':true,'smallerText' : item.name.length > 44, 'smallerTextOver50' : item.name.length > 50}">
                {{ item.name }}
              </div>
            </v-list-item>
          </v-list>
        </template>
      </v-treeview>
    </v-navigation-drawer>

    <v-app-bar app class="ma-0 pa-0" dense>
      <v-app-bar-nav-icon @click="drawer = !drawer"/>
      <v-spacer/>
      <v-btn
          icon
          @click="toggleTheme()">
        <v-icon
            class="mr-1"
            :color="($vuetify.theme.dark ? 'yellow ' : 'blue-grey') + ' darken-3'"
        >
          {{ $vuetify.theme.dark ? 'mdi-weather-night' : 'mdi-white-balance-sunny' }}
        </v-icon>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" v-on="on" :loading="logoutLoading">
            <v-icon left>mdi-account</v-icon>
            <span class="white--text">{{ $t('general.hello') }}, {{ user.first_name }}</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item class=" pa-0 ma-0">
            <v-list-item-content class="d-flex flex-row pa-0 ma-0">
              <div class="d-flex flex-row ma-0 pa-0">
                <div class="text--secondary font-weight-bold ml-3 my-3">
                  {{ user.first_name + ' ' + user.last_name }}
                </div>
              </div>
              <!--              <div class="d-flex flex-row align-center justify-center">-->
              <!--                <v-icon>mdi-account-edit</v-icon>-->
              <!--                <div class="text&#45;&#45;darken-1">Edit profile</div>-->
              <!--              </div>-->
              <div class="d-flex flex-row align-center justify-center">
                <v-btn @click="handleLogout" color="error">
                  {{ $t('general.logout') + ' ' }}
                  <v-icon>
                    mdi-logout
                  </v-icon>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {mapGetters, mapMutations} from "vuex"

export default {
  name: "DashView",
  data() {
    return {
      translation: "side_nav",
      drawer: null,
      logoutLoading: false,
      caseSensitive: false,
      search: null,
      open: [5],
      activeLink: this.$router.currentRoute.path,
      selectedItems: [],
      links: []
    }
  },
  computed: {
    ...mapGetters("auth", {
      user: "getUserData"
    }),
    filter() {
      return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
    },
  },
  created() {
    this.links = [
      // {
      //   id: 1,
      //   icon: 'mdi-home',
      //   name: this.$t(this.translation + '.home'),
      //   route: '/dashboard',
      // },
      {
        id: 2,
        icon: 'mdi-clock-check',
        name: this.$t(this.translation + '.ads_on_approval'),
        route: '/ads-on-approval'
      },
      {
        id: 3,
        icon: 'mdi-advertisements',
        name: this.$t(this.translation + '.ads'),
        route: '/ads'
      },
      {
        id: 4,
        icon: 'mdi-bell',
        name: this.$t(this.translation + '.notifications'),
        route: '/notifications'
      },
      {
        id: 5,
        icon: 'mdi-cogs',
        name: this.$t(this.translation + '.management'),
        items:
            [
              {
                id: 51,
                icon: 'mdi-badge-account',
                name: this.$t(this.translation + '.user_types'),
                route: '/user-types'
              }, {
              id: 52,
              icon: 'mdi-account',
              name: this.$t(this.translation + '.users'),
              route: '/users'
            }, {
              id: 53,
              icon: 'mdi-map',
              name: this.$t(this.translation + '.countries'),
              route: '/countries'
            }, {
              id: 54,
              icon: 'mdi-eiffel-tower',
              name: this.$t(this.translation + '.cities'),
              route: '/cities'
            }, {
              id: 55,
              icon: 'mdi-toolbox-outline',
              name: this.$t(this.translation + '.services'),
              route: '/services'
            },
              // {
              //   id: 9,
              //   icon: 'mdi-view-week',
              //   name: 'Dani',
              //   route: '/days'
              // },
              {
                id: 56,
                icon: 'mdi-cash',
                name: this.$t(this.translation + '.currency'),
                route: '/currencies'
              }
            ]
      }]
  },
  methods: {
    ...mapMutations("auth", {
      setLogout: "setLogout"
    }),
    goToLink(to) {
      if (to === undefined) return;
      if (to === null || this.$router.currentRoute.path === to) return;
      this.activeLink = to;
      this.$router.push(to)
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    handleLogout() {
      this.logoutLoading = true
      this.$axios.get('/api/logout').then((response) => {
        console.log("response", response.data)
        this.setLogout()
        localStorage.removeItem("isAuthenticated", false);
        this.$router.push('/login')
      }).catch((error) => {
        if (error.response && error.response.data) {
          this.$swal.fire({
            icon: "error",
            title: this.$t('general.error'),
            text: error.response.data.message
          })
          return
        }
        this.$swal.fire({
          icon: "error",
          title: this.$t('general.error'),
          text: this.$t('general.contact_admin_message')
        })
      }).finally(() => {
        this.logoutLoading = false
      })

    }
  }
}
</script>
<style>
.treeview-custom .v-treeview-node__toggle {
  color: white !important;
}

.v-treeview-node__root {
  padding: 0 !important;
}

.smallerText {
  font-size: 12px;
}

.smallerTextOver50 {
  font-size: 10px;
}

</style>